// Owl Carousel 2.3.4
// https://github.com/OwlCarousel2/OwlCarousel2

// Core
.owl-carousel {
	display: none;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
	position: relative;
	z-index: 1;

	.owl-stage {
		position: relative;
		-ms-touch-action: pan-Y;
		touch-action: manipulation;
		-moz-backface-visibility: hidden; }

	.owl-stage:after {
		content: ".";
		display: block;
		clear: both;
		visibility: hidden;
		line-height: 0;
		height: 0; }

	.owl-stage-outer {
		position: relative;
		overflow: hidden;
		-webkit-transform: translate3d(0px, 0px, 0px);
		transform: translate3d(0px, 0px, 0px); }

	.owl-wrapper,
	.owl-item {
		-webkit-backface-visibility: hidden;
		-moz-backface-visibility: hidden;
		-ms-backface-visibility: hidden;
		-webkit-transform: translate3d(0,0,0);
		-moz-transform: translate3d(0,0,0);
		-ms-transform: translate3d(0,0,0); }

	.owl-item {
		position: relative;
		min-height: 1px;
		float: left;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		-webkit-tap-highlight-color: transparent;
		-webkit-touch-callout: none; }

	.owl-item img {
		width: 100%; }

	.owl-nav.disabled,
	.owl-dots.disabled {
		display: none; }

	.owl-nav .owl-prev,
	.owl-nav .owl-next,
	.owl-dot {
		cursor: pointer;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none; }

	&.owl-loaded {
		display: block; }

	&.owl-loading {
		opacity: 0;
		display: block; }

	&.owl-hidden {
		opacity: 0; }

	&.owl-refresh .owl-item {
		visibility: hidden; }

	&.owl-drag .owl-item {
		touch-action: pan-y;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none; }

	&.owl-grab {
		cursor: move;
		cursor: grab; }

	&.owl-rtl {
		direction: rtl; }

	&.owl-rtl .owl-item {
		float: right; } }

// No Js
.no-js .owl-carousel {
	display: block; }

// Animate
.owl-carousel {

	.animated {
		animation-duration: 1000ms;
		animation-fill-mode: both; }

	.owl-animated-in {
		z-index: 0; }

	.owl-animated-out {
		z-index: 1; }

	.fadeOut {
		animation-name: fadeOut; } }

@keyframes fadeOut {
	0% {
		opacity: 1; }

	100% {
		opacity: 0; } }

// Autoheight
.owl-height {
	transition: height 500ms ease-in-out; }

//lazyload
.owl-carousel {

	.owl-item {

		.owl-lazy {
				opacity: 0;
				transition: opacity 400ms ease; }

		.owl-lazy[src^=""], .owl-lazy:not([src]) {
			max-height: 0; }

		img.owl-lazy {
			transform-style: preserve-3d; } } }

