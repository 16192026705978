@import url("https://fonts.googleapis.com/css?family=Exo+2:200,200i,300,300i,400,400i,500,500i,600,600i,700,700i&subset=cyrillic");
* {
  box-sizing: border-box; }
  *::before, *::after {
    box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title], abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b, strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }
  a:not([href]) {
    color: inherit;
    text-decoration: none; }
    a:not([href]):hover {
      color: inherit;
      text-decoration: none; }

pre, code, kbd, samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }
  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color; }

input, button, select, optgroup, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button, input {
  overflow: visible; }

button {
  text-transform: none; }

select {
  text-transform: none;
  word-wrap: normal; }

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"], input[type="time"], input[type="datetime-local"], input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

* {
  box-sizing: inherit; }
  *::before, *::after {
    box-sizing: inherit; }

.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container {
    max-width: 1320px; } }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px; } }

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col, .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%; }

.row-cols-4 > * {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%; }

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%; }

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%; }

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%; }

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%; }

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%; }

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%; }

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%; }

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%; }

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -ms-flex-order: -1;
  order: -1; }

.order-last {
  -ms-flex-order: 13;
  order: 13; }

.order-0 {
  -ms-flex-order: 0;
  order: 0; }

.order-1 {
  -ms-flex-order: 1;
  order: 1; }

.order-2 {
  -ms-flex-order: 2;
  order: 2; }

.order-3 {
  -ms-flex-order: 3;
  order: 3; }

.order-4 {
  -ms-flex-order: 4;
  order: 4; }

.order-5 {
  -ms-flex-order: 5;
  order: 5; }

.order-6 {
  -ms-flex-order: 6;
  order: 6; }

.order-7 {
  -ms-flex-order: 7;
  order: 7; }

.order-8 {
  -ms-flex-order: 8;
  order: 8; }

.order-9 {
  -ms-flex-order: 9;
  order: 9; }

.order-10 {
  -ms-flex-order: 10;
  order: 10; }

.order-11 {
  -ms-flex-order: 11;
  order: 11; }

.order-12 {
  -ms-flex-order: 12;
  order: 12; }

.offset-1 {
  margin-left: 8.333333%; }

.offset-2 {
  margin-left: 16.666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.333333%; }

.offset-5 {
  margin-left: 41.666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.333333%; }

.offset-8 {
  margin-left: 66.666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.333333%; }

.offset-11 {
  margin-left: 91.666667%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .row-cols-sm-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-sm-last {
    -ms-flex-order: 13;
    order: 13; }
  .order-sm-0 {
    -ms-flex-order: 0;
    order: 0; }
  .order-sm-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-sm-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-sm-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-sm-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-sm-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-sm-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-sm-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-sm-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-sm-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-sm-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-sm-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-sm-12 {
    -ms-flex-order: 12;
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.333333%; }
  .offset-sm-2 {
    margin-left: 16.666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.333333%; }
  .offset-sm-5 {
    margin-left: 41.666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.333333%; }
  .offset-sm-8 {
    margin-left: 66.666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.333333%; }
  .offset-sm-11 {
    margin-left: 91.666667%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .row-cols-md-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-md-last {
    -ms-flex-order: 13;
    order: 13; }
  .order-md-0 {
    -ms-flex-order: 0;
    order: 0; }
  .order-md-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-md-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-md-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-md-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-md-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-md-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-md-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-md-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-md-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-md-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-md-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-md-12 {
    -ms-flex-order: 12;
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.333333%; }
  .offset-md-2 {
    margin-left: 16.666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.333333%; }
  .offset-md-5 {
    margin-left: 41.666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.333333%; }
  .offset-md-8 {
    margin-left: 66.666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.333333%; }
  .offset-md-11 {
    margin-left: 91.666667%; } }

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .row-cols-lg-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-lg-last {
    -ms-flex-order: 13;
    order: 13; }
  .order-lg-0 {
    -ms-flex-order: 0;
    order: 0; }
  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.333333%; }
  .offset-lg-2 {
    margin-left: 16.666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.333333%; }
  .offset-lg-5 {
    margin-left: 41.666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.333333%; }
  .offset-lg-8 {
    margin-left: 66.666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.333333%; }
  .offset-lg-11 {
    margin-left: 91.666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .row-cols-xl-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-xl-last {
    -ms-flex-order: 13;
    order: 13; }
  .order-xl-0 {
    -ms-flex-order: 0;
    order: 0; }
  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.333333%; }
  .offset-xl-2 {
    margin-left: 16.666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.333333%; }
  .offset-xl-5 {
    margin-left: 41.666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.333333%; }
  .offset-xl-8 {
    margin-left: 66.666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.333333%; }
  .offset-xl-11 {
    margin-left: 91.666667%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important; }

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important; }

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important; }

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important; }

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important; }

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important; }

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important; }

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important; }

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important; }

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important; }

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important; }

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important; }

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important; }

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important; }

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important; }

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important; }

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important; }

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important; }

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important; }

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important; }

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important; }

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important; }

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important; }

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important; }

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important; }

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important; }

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important; }

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important; }

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important; }

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important; }

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-md-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

.m-0 {
  margin: 0 !important; }

.mt-0, .my-0 {
  margin-top: 0 !important; }

.mr-0, .mx-0 {
  margin-right: 0 !important; }

.mb-0, .my-0 {
  margin-bottom: 0 !important; }

.ml-0, .mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1, .my-1 {
  margin-top: 0.25rem !important; }

.mr-1, .mx-1 {
  margin-right: 0.25rem !important; }

.mb-1, .my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1, .mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2, .my-2 {
  margin-top: 0.5rem !important; }

.mr-2, .mx-2 {
  margin-right: 0.5rem !important; }

.mb-2, .my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2, .mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3, .my-3 {
  margin-top: 1rem !important; }

.mr-3, .mx-3 {
  margin-right: 1rem !important; }

.mb-3, .my-3 {
  margin-bottom: 1rem !important; }

.ml-3, .mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4, .my-4 {
  margin-top: 1.5rem !important; }

.mr-4, .mx-4 {
  margin-right: 1.5rem !important; }

.mb-4, .my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4, .mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5, .my-5 {
  margin-top: 3rem !important; }

.mr-5, .mx-5 {
  margin-right: 3rem !important; }

.mb-5, .my-5 {
  margin-bottom: 3rem !important; }

.ml-5, .mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0, .py-0 {
  padding-top: 0 !important; }

.pr-0, .px-0 {
  padding-right: 0 !important; }

.pb-0, .py-0 {
  padding-bottom: 0 !important; }

.pl-0, .px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1, .py-1 {
  padding-top: 0.25rem !important; }

.pr-1, .px-1 {
  padding-right: 0.25rem !important; }

.pb-1, .py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1, .px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2, .py-2 {
  padding-top: 0.5rem !important; }

.pr-2, .px-2 {
  padding-right: 0.5rem !important; }

.pb-2, .py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2, .px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3, .py-3 {
  padding-top: 1rem !important; }

.pr-3, .px-3 {
  padding-right: 1rem !important; }

.pb-3, .py-3 {
  padding-bottom: 1rem !important; }

.pl-3, .px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4, .py-4 {
  padding-top: 1.5rem !important; }

.pr-4, .px-4 {
  padding-right: 1.5rem !important; }

.pb-4, .py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4, .px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5, .py-5 {
  padding-top: 3rem !important; }

.pr-5, .px-5 {
  padding-right: 3rem !important; }

.pb-5, .py-5 {
  padding-bottom: 3rem !important; }

.pl-5, .px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1, .my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1, .mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1, .my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1, .mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2, .my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2, .mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2, .my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2, .mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3, .my-n3 {
  margin-top: -1rem !important; }

.mr-n3, .mx-n3 {
  margin-right: -1rem !important; }

.mb-n3, .my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3, .mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4, .my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4, .mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4, .my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4, .mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5, .my-n5 {
  margin-top: -3rem !important; }

.mr-n5, .mx-n5 {
  margin-right: -3rem !important; }

.mb-n5, .my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5, .mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto, .my-auto {
  margin-top: auto !important; }

.mr-auto, .mx-auto {
  margin-right: auto !important; }

.mb-auto, .my-auto {
  margin-bottom: auto !important; }

.ml-auto, .mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0, .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0, .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0, .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0, .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1, .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1, .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1, .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1, .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2, .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2, .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2, .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2, .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3, .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3, .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3, .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3, .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4, .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4, .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4, .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4, .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5, .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5, .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5, .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5, .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0, .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0, .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0, .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0, .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1, .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1, .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1, .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1, .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2, .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2, .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2, .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2, .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3, .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3, .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3, .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3, .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4, .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4, .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4, .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4, .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5, .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5, .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5, .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5, .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1, .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1, .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1, .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1, .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2, .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2, .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2, .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2, .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3, .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3, .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3, .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3, .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4, .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4, .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4, .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4, .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5, .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5, .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5, .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5, .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto, .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto, .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto, .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto, .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0, .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0, .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0, .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0, .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1, .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1, .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1, .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1, .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2, .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2, .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2, .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2, .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3, .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3, .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3, .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3, .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4, .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4, .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4, .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4, .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5, .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5, .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5, .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5, .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0, .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0, .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0, .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0, .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1, .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1, .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1, .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1, .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2, .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2, .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2, .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2, .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3, .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3, .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3, .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3, .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4, .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4, .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4, .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4, .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5, .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5, .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5, .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5, .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1, .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1, .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1, .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1, .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2, .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2, .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2, .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2, .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3, .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3, .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3, .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3, .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4, .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4, .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4, .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4, .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5, .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5, .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5, .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5, .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto, .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto, .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto, .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto, .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0, .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0, .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0, .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0, .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1, .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1, .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1, .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1, .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2, .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2, .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2, .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2, .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3, .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3, .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3, .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3, .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4, .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4, .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4, .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4, .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5, .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5, .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5, .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5, .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0, .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0, .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0, .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0, .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1, .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1, .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1, .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1, .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2, .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2, .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2, .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2, .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3, .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3, .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3, .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3, .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4, .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4, .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4, .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4, .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5, .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5, .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5, .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5, .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1, .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1, .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1, .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1, .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2, .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2, .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2, .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2, .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3, .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3, .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3, .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3, .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4, .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4, .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4, .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4, .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5, .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5, .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5, .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5, .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto, .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto, .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto, .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto, .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0, .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0, .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0, .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0, .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1, .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1, .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1, .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1, .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2, .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2, .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2, .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2, .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3, .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3, .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3, .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3, .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4, .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4, .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4, .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4, .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5, .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5, .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5, .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5, .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0, .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0, .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0, .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0, .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1, .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1, .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1, .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1, .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2, .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2, .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2, .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2, .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3, .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3, .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3, .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3, .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4, .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4, .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4, .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4, .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5, .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5, .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5, .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5, .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1, .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1, .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1, .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1, .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2, .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2, .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2, .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2, .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3, .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3, .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3, .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3, .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4, .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4, .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4, .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4, .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5, .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5, .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5, .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5, .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto, .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto, .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto, .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto, .mx-xl-auto {
    margin-left: auto !important; } }

@font-face {
  font-family: 'icons';
  src: url("../icons/icons.eot?chssw7"), url("../icons/icons.eot?chssw7#iefix") format("embedded-opentype"), url("../icons/icons.ttf?chssw7") format("truetype"), url("../icons/icons.woff?chssw7") format("woff"), url("../icons/icons.svg?chssw7#icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"] {
  font-family: 'icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[class*=" icon-"] {
  font-family: 'icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-ag-au:before {
  content: "\E001"; }

.icon-ag:before {
  content: "\E002"; }

.icon-arrow:before {
  content: "\E003"; }

.icon-au:before {
  content: "\E004"; }

.icon-clock:before {
  content: "\E005"; }

.icon-cross:before {
  content: "\E006"; }

.icon-diamond:before {
  content: "\E007"; }

.icon-facebook:before {
  content: "\E008"; }

.icon-gold:before {
  content: "\E009"; }

.icon-instagram:before {
  content: "\E00A"; }

.icon-location:before {
  content: "\E00B"; }

.icon-love:before {
  content: "\E00C"; }

.icon-navigation:before {
  content: "\E00D"; }

.icon-odnoklassniki:before {
  content: "\E00E"; }

.icon-phone:before {
  content: "\E00F"; }

.icon-plus:before {
  content: "\E010"; }

.icon-quality:before {
  content: "\E011"; }

.icon-scrolldown:before {
  content: "\E012"; }

.icon-telegram:before {
  content: "\E013"; }

.icon-tik-tok:before {
  content: "\E014"; }

.icon-vk:before {
  content: "\E015"; }

.icon-ya-zen:before {
  content: "\E016"; }

.icon-youtube:before {
  content: "\E017"; }

body {
  font-family: 'Exo 2', sans-serif !important;
  color: #000; }

.scroll-lock {
  overflow: hidden; }

main {
  padding: 0 30px !important; }
  @media (max-width: 992px) {
    main {
      padding: 72px 15px 68px 15px !important; } }

a:active, a:focus {
  outline: none !important; }

textarea:focus, input:focus {
  outline: none; }

*:focus {
  outline: none; }

button:active {
  outline: none !important; }

button:focus {
  outline: none !important; }

button::-moz-focus-inner {
  border: 0 !important; }

button:active, button:focus {
  outline: none; }

button::-moz-focus-inner {
  border: 0; }

a[href^="tel"] {
  color: inherit;
  text-decoration: none !important; }

h2 {
  font-size: 24px;
  line-height: 26px;
  font-weight: 300; }

h3 {
  font-size: 20px;
  line-height: 22px;
  font-weight: 300; }

h4 {
  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
  text-transform: uppercase; }

p {
  font-size: 16px;
  line-height: 26px;
  font-weight: 300;
  margin: 20px 0; }

b {
  font-weight: 700; }

strong {
  font-weight: 500; }

a {
  color: #000;
  text-decoration: none;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  a:hover {
    color: #000;
    text-decoration: none; }

ul {
  padding: 0 0 0 20px; }
  ul li {
    font-weight: 300; }

ol {
  padding: 0 0 0 20px; }
  ol li {
    font-weight: 300; }

.clear {
  clear: both; }

.clear-m {
  clear: both; }

.clearfix {
  clear: both; }

.fw300 {
  font-weight: 300; }

.fw400 {
  font-weight: 400; }

.fw500 {
  font-weight: 500; }

.fw700 {
  font-weight: 700; }

.fs-normal {
  font-style: normal; }

.fs-italic {
  font-style: italic; }

.fs-10 {
  font-size: 10px; }

.fs-12 {
  font-size: 12px; }

.fs-14 {
  font-size: 14px; }

.fs-16 {
  font-size: 16px; }

.fs-18 {
  font-size: 18px; }

.fs-20 {
  font-size: 20px; }

.fs-22 {
  font-size: 22px; }

.fs-24 {
  font-size: 24px; }

.fs-26 {
  font-size: 26px; }

.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  z-index: 1; }
  .owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    touch-action: manipulation;
    -moz-backface-visibility: hidden; }
  .owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px); }
  .owl-carousel .owl-wrapper,
  .owl-carousel .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0); }
  .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none; }
  .owl-carousel .owl-item img {
    width: 100%; }
  .owl-carousel .owl-nav.disabled,
  .owl-carousel .owl-dots.disabled {
    display: none; }
  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next,
  .owl-carousel .owl-dot {
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-loaded {
    display: block; }
  .owl-carousel.owl-loading {
    opacity: 0;
    display: block; }
  .owl-carousel.owl-hidden {
    opacity: 0; }
  .owl-carousel.owl-refresh .owl-item {
    visibility: hidden; }
  .owl-carousel.owl-drag .owl-item {
    touch-action: pan-y;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-grab {
    cursor: move;
    cursor: grab; }
  .owl-carousel.owl-rtl {
    direction: rtl; }
  .owl-carousel.owl-rtl .owl-item {
    float: right; }

.no-js .owl-carousel {
  display: block; }

.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.owl-height {
  transition: height 500ms ease-in-out; }

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease; }

.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0; }

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d; }

.scroll-wrapper {
  overflow: hidden !important;
  padding: 0 !important;
  position: relative; }
  .scroll-wrapper > .scroll-content {
    border: none !important;
    box-sizing: content-box !important;
    height: auto;
    left: 0;
    margin: 0;
    max-height: none;
    max-width: none !important;
    overflow: scroll !important;
    padding: 0;
    position: relative !important;
    top: 0;
    width: auto !important; }
    .scroll-wrapper > .scroll-content::-webkit-scrollbar {
      height: 0;
      width: 0; }
  .scroll-wrapper.scroll--rtl {
    direction: rtl; }

.scroll-element {
  box-sizing: content-box;
  display: none; }
  .scroll-element div {
    box-sizing: content-box; }
  .scroll-element .scroll-bar,
  .scroll-element .scroll-arrow {
    cursor: default; }
  .scroll-element.scroll-x.scroll-scrollx_visible, .scroll-element.scroll-y.scroll-scrolly_visible {
    display: block; }

.scroll-textarea {
  border: 1px solid #cccccc;
  border-top-color: #999999; }
  .scroll-textarea > .scroll-content {
    overflow: hidden !important; }
    .scroll-textarea > .scroll-content > textarea {
      border: none !important;
      box-sizing: border-box;
      height: 100% !important;
      margin: 0;
      max-height: none !important;
      max-width: none !important;
      overflow: scroll !important;
      outline: none;
      padding: 2px;
      position: relative !important;
      top: 0;
      width: 100% !important; }
      .scroll-textarea > .scroll-content > textarea::-webkit-scrollbar {
        height: 0;
        width: 0; }

.scrollbar-inner > .scroll-element,
.scrollbar-inner > .scroll-element div {
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 10; }

.scrollbar-inner > .scroll-element div {
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%; }

.scrollbar-inner > .scroll-element.scroll-x {
  bottom: 2px;
  height: 8px;
  left: 0;
  width: 100%; }

.scrollbar-inner > .scroll-element.scroll-y {
  height: 100%;
  right: 2px;
  top: 0;
  width: 8px; }

.scrollbar-inner > .scroll-element .scroll-element_outer {
  overflow: hidden; }

.scrollbar-inner > .scroll-element .scroll-element_outer,
.scrollbar-inner > .scroll-element .scroll-element_track,
.scrollbar-inner > .scroll-element .scroll-bar {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px; }

.scrollbar-inner > .scroll-element .scroll-element_track,
.scrollbar-inner > .scroll-element .scroll-bar {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  filter: alpha(opacity=40);
  opacity: 0.4; }

.scrollbar-inner > .scroll-element .scroll-element_track {
  background-color: #e0e0e0; }

.scrollbar-inner > .scroll-element .scroll-bar {
  background-color: #c2c2c2; }

.scrollbar-inner > .scroll-element:hover .scroll-bar {
  background-color: #919191; }

.scrollbar-inner > .scroll-element.scroll-draggable .scroll-bar {
  background-color: #919191; }

.scrollbar-inner > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track {
  left: -12px; }

.scrollbar-inner > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track {
  top: -12px; }

.scrollbar-inner > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size {
  left: -12px; }

.scrollbar-inner > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size {
  top: -12px; }

.scrollbar-outer > .scroll-element,
.scrollbar-outer > .scroll-element div {
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 10; }

.scrollbar-outer > .scroll-element {
  background-color: #ffffff; }

.scrollbar-outer > .scroll-element div {
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%; }

.scrollbar-outer > .scroll-element.scroll-x {
  bottom: 0;
  height: 12px;
  left: 0;
  width: 100%; }

.scrollbar-outer > .scroll-element.scroll-y {
  height: 100%;
  right: 0;
  top: 0;
  width: 12px; }

.scrollbar-outer > .scroll-element.scroll-x .scroll-element_outer {
  height: 8px;
  top: 2px; }

.scrollbar-outer > .scroll-element.scroll-y .scroll-element_outer {
  left: 2px;
  width: 8px; }

.scrollbar-outer > .scroll-element .scroll-element_outer {
  overflow: hidden; }

.scrollbar-outer > .scroll-element .scroll-element_track {
  background-color: #eeeeee; }

.scrollbar-outer > .scroll-element .scroll-element_outer,
.scrollbar-outer > .scroll-element .scroll-element_track,
.scrollbar-outer > .scroll-element .scroll-bar {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px; }

.scrollbar-outer > .scroll-element .scroll-bar {
  background-color: #d9d9d9; }

.scrollbar-outer > .scroll-element .scroll-bar:hover {
  background-color: #c2c2c2; }

.scrollbar-outer > .scroll-element.scroll-draggable .scroll-bar {
  background-color: #919191; }

.scrollbar-outer > .scroll-content.scroll-scrolly_visible {
  left: -12px;
  margin-left: 12px; }

.scrollbar-outer > .scroll-content.scroll-scrollx_visible {
  top: -12px;
  margin-top: 12px; }

.scrollbar-outer > .scroll-element.scroll-x .scroll-bar {
  min-width: 10px; }

.scrollbar-outer > .scroll-element.scroll-y .scroll-bar {
  min-height: 10px; }

.scrollbar-outer > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track {
  left: -14px; }

.scrollbar-outer > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track {
  top: -14px; }

.scrollbar-outer > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size {
  left: -14px; }

.scrollbar-outer > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size {
  top: -14px; }

.scrollbar-dynamic > .scroll-element,
.scrollbar-dynamic > .scroll-element div {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 10; }

.scrollbar-dynamic > .scroll-element div {
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%; }

.scrollbar-dynamic > .scroll-element.scroll-x {
  bottom: 2px;
  height: 7px;
  left: 0;
  min-width: 100%;
  width: 100%; }

.scrollbar-dynamic > .scroll-element.scroll-y {
  height: 100%;
  min-height: 100%;
  right: 2px;
  top: 0;
  width: 7px; }

.scrollbar-dynamic > .scroll-element .scroll-element_outer {
  opacity: 0.3;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px; }

.scrollbar-dynamic > .scroll-element .scroll-element_size {
  background-color: #cccccc;
  opacity: 0;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  -ms-transition: opacity 0.2s;
  transition: opacity 0.2s; }

.scrollbar-dynamic > .scroll-element .scroll-bar {
  background-color: #6c6e71;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px; }

.scrollbar-dynamic > .scroll-element.scroll-x .scroll-bar {
  bottom: 0;
  height: 3px;
  min-width: 24px;
  top: auto; }

.scrollbar-dynamic > .scroll-element.scroll-y .scroll-bar {
  left: auto;
  min-height: 24px;
  right: 0;
  width: 3px; }

.scrollbar-dynamic > .scroll-element.scroll-x .scroll-element_outer {
  bottom: 0;
  top: auto;
  left: 2px;
  -webkit-transition: height 0.2s;
  -moz-transition: height 0.2s;
  -o-transition: height 0.2s;
  -ms-transition: height 0.2s;
  transition: height 0.2s; }

.scrollbar-dynamic > .scroll-element.scroll-y .scroll-element_outer {
  left: auto;
  right: 0;
  top: 2px;
  -webkit-transition: width 0.2s;
  -moz-transition: width 0.2s;
  -o-transition: width 0.2s;
  -ms-transition: width 0.2s;
  transition: width 0.2s; }

.scrollbar-dynamic > .scroll-element.scroll-x .scroll-element_size {
  left: -4px; }

.scrollbar-dynamic > .scroll-element.scroll-y .scroll-element_size {
  top: -4px; }

.scrollbar-dynamic > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size {
  left: -11px; }

.scrollbar-dynamic > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size {
  top: -11px; }

.scrollbar-dynamic > .scroll-element:hover .scroll-element_outer,
.scrollbar-dynamic > .scroll-element.scroll-draggable .scroll-element_outer {
  overflow: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
  opacity: 0.7; }

.scrollbar-dynamic > .scroll-element:hover .scroll-element_outer .scroll-element_size,
.scrollbar-dynamic > .scroll-element.scroll-draggable .scroll-element_outer .scroll-element_size {
  opacity: 1; }

.scrollbar-dynamic > .scroll-element:hover .scroll-element_outer .scroll-bar,
.scrollbar-dynamic > .scroll-element.scroll-draggable .scroll-element_outer .scroll-bar {
  height: 100%;
  width: 100%;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px; }

.scrollbar-dynamic > .scroll-element.scroll-x:hover .scroll-element_outer,
.scrollbar-dynamic > .scroll-element.scroll-x.scroll-draggable .scroll-element_outer {
  height: 8px;
  min-height: 7px; }

.scrollbar-dynamic > .scroll-element.scroll-y:hover .scroll-element_outer,
.scrollbar-dynamic > .scroll-element.scroll-y.scroll-draggable .scroll-element_outer {
  min-width: 7px;
  width: 8px; }

@media (max-width: 1200px) {
  .header {
    display: none; } }

.header .ccrow {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.header .fbar {
  padding: 35px 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3); }
  .header .fbar .logo img {
    width: 210px; }
  .header .fbar .contacts {
    font-weight: 400;
    font-size: 15px; }
    .header .fbar .contacts .cgrid {
      display: flex;
      align-items: center; }
      .header .fbar .contacts .cgrid .ccol {
        display: flex;
        align-items: center;
        margin: 0px 18px; }
    .header .fbar .contacts .ico {
      position: relative;
      top: 3px;
      color: #d0aa3a;
      margin: 0 10px 0 0; }
      .header .fbar .contacts .ico span {
        font-size: 18px !important; }
    .header .fbar .contacts .inf {
      color: rgba(255, 255, 255, 0.9);
      font-weight: 300; }

.header .sbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px; }
  .header .sbar .navigation {
    float: left; }
    .header .sbar .navigation ul {
      display: flex;
      margin: 0;
      padding: 0;
      list-style-type: none; }
      .header .sbar .navigation ul li {
        margin: 0 22px 0 0; }
        .header .sbar .navigation ul li a {
          display: flex;
          position: relative;
          font-size: 16px;
          color: rgba(255, 255, 255, 0.9);
          transition: 0.3s;
          font-weight: 300; }
          .header .sbar .navigation ul li a span {
            position: absolute;
            right: -14px;
            top: -3px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            color: #fff;
            background: #be0b1c;
            font-size: 9px;
            line-height: 9px;
            font-weight: 700;
            height: 17px;
            width: 17px;
            border-radius: 50px; }
        .header .sbar .navigation ul li a:hover {
          text-decoration: none;
          color: #d0aa3a;
          border-color: #d0aa3a; }
          .header .sbar .navigation ul li a:hover span {
            color: #fff; }
  .header .sbar .social ul {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style-type: none; }
    .header .sbar .social ul li {
      margin: 0 0 0 20px; }
      .header .sbar .social ul li a {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 18px;
        color: rgba(255, 255, 255, 0.9);
        transition: 0.3s; }
      .header .sbar .social ul li a:hover {
        color: #d0aa3a;
        text-decoration: none; }

.headerMobile {
  position: sticky;
  top: 0;
  display: none;
  background: #fff;
  z-index: 999999;
  box-shadow: -2px 2px 15px 5px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: -2px 2px 15px 5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: -2px 2px 15px 5px rgba(0, 0, 0, 0.15); }
  .headerMobile_nav-open {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none; }
  @media (max-width: 1200px) {
    .headerMobile {
      display: block; } }
  .headerMobile__inner {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0; }
  .headerMobile .logo img {
    height: 22px; }
  .headerMobile .mobileNavButton {
    position: relative; }
    .headerMobile .mobileNavButton__open {
      position: relative;
      z-index: 9999998; }
    .headerMobile .mobileNavButton__close {
      position: absolute;
      top: -3px;
      left: -2px;
      z-index: 9999999;
      background: #fff;
      padding: 5px;
      font-size: 20px !important; }
    .headerMobile .mobileNavButton a {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      font-size: 24px;
      color: #d0aa3a; }

.mobile-navigation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: #fff;
  padding: 70px 15px 30px 15px; }
  .mobile-navigation__nav {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center; }
    .mobile-navigation__nav ul {
      padding: 0;
      list-style: none; }
      .mobile-navigation__nav ul li {
        display: flex;
        justify-content: center;
        margin: 0 0 12px 0; }
        .mobile-navigation__nav ul li a {
          display: flex;
          position: relative;
          font-size: 18px;
          color: #000;
          transition: 0.3s;
          font-weight: 600; }
          .mobile-navigation__nav ul li a span {
            position: absolute;
            right: -20px;
            top: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            color: #fff;
            background: #be0b1c;
            font-size: 9px;
            line-height: 9px;
            font-weight: 700;
            height: 17px;
            width: 17px;
            border-radius: 50px; }
  .mobile-navigation__contacts {
    margin: 0 0 15px 0; }
    .mobile-navigation__contacts ul {
      padding: 0;
      list-style: none; }
      .mobile-navigation__contacts ul li {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        line-height: 16px;
        font-weight: 500;
        color: #000;
        margin: 15px 0 0 0; }
        .mobile-navigation__contacts ul li span {
          position: relative;
          top: 1px;
          color: #d0aa3a;
          font-size: 20px;
          line-height: 20px;
          margin: 0 8px 0 0; }
        .mobile-navigation__contacts ul li a {
          display: flex;
          align-items: center;
          color: #000;
          font-weight: 500; }
          .mobile-navigation__contacts ul li a span {
            position: relative;
            top: 1px;
            color: #d0aa3a;
            font-size: 20px;
            line-height: 20px;
            margin: 0 8px 0 0; }
  .mobile-navigation__callback {
    display: flex;
    justify-content: center; }
  .mobile-navigation__categories {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 999999;
    padding: 70px 15px 15px 15px;
    display: flex;
    flex-direction: column; }
    .mobile-navigation__categories__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0 20px 0;
      padding: 0 0 20px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
      .mobile-navigation__categories__header__back a {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 14px;
        font-weight: 800;
        color: #000;
        text-transform: uppercase; }
        .mobile-navigation__categories__header__back a span {
          margin: 0 5px 0 0; }
      .mobile-navigation__categories__header__title {
        position: relative;
        font-size: 20px;
        line-height: 20px;
        font-weight: 800;
        color: #000; }
        .mobile-navigation__categories__header__title span {
          position: absolute;
          right: -20px;
          top: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          color: #fff;
          background: #be0b1c;
          font-size: 9px;
          line-height: 9px;
          font-weight: 700;
          height: 17px;
          width: 17px;
          border-radius: 50px; }
    .mobile-navigation__categories__nav {
      height: 100%;
      overflow-x: scroll; }
      .mobile-navigation__categories__nav ul {
        padding: 0;
        list-style: none; }
        .mobile-navigation__categories__nav ul li {
          display: flex;
          justify-content: center;
          margin: 20px 0; }
          .mobile-navigation__categories__nav ul li:first-child {
            margin: 0 0 20px 0; }
          .mobile-navigation__categories__nav ul li:last-child {
            margin: 20px 0 0 0; }
          .mobile-navigation__categories__nav ul li a {
            display: flex;
            position: relative;
            font-size: 16px;
            line-height: 20px;
            color: #000;
            text-decoration: underline;
            font-weight: 500;
            text-align: center;
            padding: 0 20px; }

.weddingTitleSection {
  background: url("../img/bgWedding.jpg") no-repeat center center !important; }

.voucherTitleSection {
  background: url("../img/bgVoucher.jpg") no-repeat center center !important; }

.title-section-mainpage {
  background: url("../img/bg.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; }

.title-section {
  position: relative;
  padding: 0px 0px 0px 0px;
  background-color: #111111; }
  .title-section__container {
    position: relative;
    z-index: 1;
    background: rgba(0, 0, 0, 0.6); }

.title-elements {
  padding: 90px 0px 100px 0px;
  text-align: center; }
  @media (max-width: 1600px) {
    .title-elements {
      padding: 60px 0px 80px 0px; } }
  @media (max-width: 1500px) {
    .title-elements {
      padding: 50px 0px 70px 0px; } }
  @media (max-width: 992px) {
    .title-elements {
      padding: 40px 0px 50px 0px; } }
  .title-elements .title1 {
    color: #d0aa3a;
    font-weight: 500;
    font-size: 23px;
    text-transform: uppercase; }
    .title-elements .title1 h1 {
      color: #d0aa3a;
      font-weight: 800;
      font-size: 32px;
      text-transform: uppercase; }
      @media (max-width: 992px) {
        .title-elements .title1 h1 {
          font-size: 20px; } }
  .title-elements .titleimg {
    margin: 35px 0; }
    @media (max-width: 1400px) {
      .title-elements .titleimg {
        margin: 20px 0; } }
    .title-elements .titleimg img {
      width: 383px; }
      @media (max-width: 440px) {
        .title-elements .titleimg img {
          width: 100%; } }
  .title-elements .title2 {
    color: #fff;
    font-weight: 200;
    font-size: 22px;
    text-transform: uppercase;
    margin: 0 0 50px 0; }
    @media (max-width: 992px) {
      .title-elements .title2 {
        font-size: 16px;
        margin: 0 0 30px 0; } }
  .title-elements .title3 {
    color: #d0aa3a;
    font-size: 18px;
    line-height: 18px;
    font-weight: 800; }
    @media (max-width: 992px) {
      .title-elements .title3 {
        font-size: 14px;
        line-height: 14px; } }
  .title-elements .title4 {
    color: #d0aa3a;
    font-size: 28px;
    line-height: 32px;
    text-transform: uppercase;
    font-weight: 300; }
    @media (max-width: 992px) {
      .title-elements .title4 {
        font-size: 20px;
        line-height: 24px; } }

.title-page {
  padding: 50px 0px 70px 0px; }
  @media (max-width: 1200px) {
    .title-page {
      padding: 30px 0px 35px 0px; } }
  .title-page .title {
    color: #d0aa3a;
    font-size: 32px;
    line-height: 40px;
    font-weight: 800; }
    @media (max-width: 1200px) {
      .title-page .title {
        font-size: 22px;
        line-height: 30px; } }

.mainbut {
  text-align: center;
  margin: 0 auto;
  margin-bottom: 80px; }

.scrolldown {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0 40px 0; }
  @media (max-width: 1600px) {
    .scrolldown {
      padding: 0 0 30px 0; } }
  .scrolldown__text {
    color: #fff;
    font-size: 12px;
    line-height: 12px;
    font-weight: 300;
    text-transform: uppercase;
    margin: 0 0 15px 0;
    opacity: 0.2; }
  .scrolldown__el {
    display: flex;
    justify-content: center;
    width: 25px;
    height: 40px;
    border: 2px solid #fff;
    border-radius: 10px;
    opacity: 0.4; }
    .scrolldown__el__dot {
      position: relative;
      top: 6px;
      width: 5px;
      height: 5px;
      border-radius: 5px;
      background: #fff;
      animation: scroll 1.4s ease;
      animation-iteration-count: infinite; }

@keyframes scroll {
  0% {
    transform: translateY(0);
    opacity: 0; }
  20% {
    opacity: 1; }
  70% {
    opacity: 1; }
  100% {
    transform: translateY(20px);
    opacity: 0; } }

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  padding: 10px 25px 10px 25px;
  border: 1px solid;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  text-decoration: none;
  outline: none;
  transition: 0.3s; }
  .button .nxtIcon {
    position: relative;
    font-size: 18px;
    margin: 0 8px 0 0; }

.button-big {
  padding: 20px 25px; }
  @media (max-width: 992px) {
    .button-big {
      padding: 15px 25px; } }

.button:active {
  outline: none;
  text-decoration: none; }

.button:focus {
  text-decoration: none;
  outline: none; }

.button:hover {
  text-decoration: none; }

.bgold {
  color: #d0aa3a;
  border-color: #d0aa3a; }
  .bgold:hover {
    color: rgba(255, 255, 255, 0.9);
    background: #d0aa3a; }

.bgold-filled {
  color: #fff;
  border-color: #d0aa3a;
  background: #d0aa3a; }
  .bgold-filled:hover {
    color: rgba(255, 255, 255, 0.9);
    background: #bd9729; }

.bwhite {
  color: #fff;
  border-color: #fff; }
  .bwhite:hover {
    color: #d0aa3a;
    background: #fff; }

.bwhite-filled {
  color: #d0aa3a;
  border-color: #fff;
  background: #fff; }
  .bwhite-filled:hover {
    color: #fff;
    background: #616161;
    border-color: #616161; }

.green-filled {
  color: #fff;
  background: #61822e;
  border-color: #61822e; }
  .green-filled:hover {
    color: #fff;
    background: #445c1f;
    border-color: #445c1f; }

.green-filled-2 {
  color: #fff;
  background: #76a232;
  border-color: #76a232; }
  .green-filled-2:hover {
    color: #fff;
    background: #679127;
    border-color: #679127; }

.blue-filled {
  color: #fff;
  border-color: #0091da;
  background: #0091da; }
  .blue-filled:hover {
    color: #fff;
    background: #007dbb; }

input[type=text],
input[type=time],
input[type=date] {
  border: none;
  border-bottom: 1px solid #d0aa3a;
  padding: 20px 0;
  background: #fff;
  color: #000;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.6px;
  background: transparent;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 100%;
  -webkit-appearance: none; }
  @media (max-width: 992px) {
    input[type=text],
    input[type=time],
    input[type=date] {
      padding: 16px 0;
      font-size: 16px;
      line-height: 16px; } }
  input[type=text]:focus,
  input[type=time]:focus,
  input[type=date]:focus {
    outline: none !important; }

.popup {
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.4); }
  @media (max-width: 992px) {
    .popup {
      background: #fff; } }
  .popup__close-but {
    position: absolute;
    top: 30px;
    right: 30px; }
    @media (max-width: 992px) {
      .popup__close-but {
        top: 0;
        right: 0; } }
    .popup__close-but a {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      padding: 15px;
      border-radius: 5px;
      color: #000;
      font-size: 18px;
      line-height: 18px; }
      @media (max-width: 992px) {
        .popup__close-but a {
          font-size: 24px;
          line-height: 24px; } }
      .popup__close-but a:hover {
        background: #d0aa3a;
        color: #fff; }
        @media (max-width: 992px) {
          .popup__close-but a:hover {
            background: #fff;
            color: #000; } }
  .popup__inner {
    background: #fff;
    border-radius: 15px;
    padding: 60px 80px; }
    @media (max-width: 992px) {
      .popup__inner {
        padding: 30px;
        border-radius: 0; } }

.callback-popup__inner {
  width: 600px; }
  @media (max-width: 992px) {
    .callback-popup__inner {
      width: 100%; } }

.callback-popup__title {
  font-size: 38px;
  line-height: 40px;
  font-weight: 800;
  color: #d0aa3a;
  text-align: center;
  margin: 0 0 20px 0; }
  @media (max-width: 992px) {
    .callback-popup__title {
      font-size: 28px;
      line-height: 30px;
      margin: 0 0 10px 0; } }

.callback-popup__form__field {
  width: 100%;
  margin: 10px 0; }
  .callback-popup__form__field button {
    width: 100%;
    margin: 40px 0 0 0; }
    @media (max-width: 992px) {
      .callback-popup__form__field button {
        margin: 20px 0 0 0; } }

.callback-popup__agreement {
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.4);
  margin: 20px 0 0 0; }
  @media (max-width: 992px) {
    .callback-popup__agreement {
      margin: 15px 0 0 0; } }
  .callback-popup__agreement a {
    color: rgba(0, 0, 0, 0.4);
    text-decoration: underline; }
    .callback-popup__agreement a:hover {
      color: #d0aa3a; }

.footer {
  position: relative;
  width: 100%;
  z-index: 10;
  padding: 40px 0px;
  background: #111111; }
  .footer .ccrow {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 1200px) {
      .footer .ccrow {
        flex-wrap: wrap; } }
    @media (max-width: 992px) {
      .footer .ccrow {
        justify-content: center; } }
    @media (max-width: 1200px) {
      .footer .ccrow .cccol1 {
        order: 1; } }
    @media (max-width: 992px) {
      .footer .ccrow .cccol1 {
        order: 1; } }
    @media (max-width: 1200px) {
      .footer .ccrow .cccol2 {
        width: 100%;
        order: 3; } }
    @media (max-width: 992px) {
      .footer .ccrow .cccol2 {
        order: 2; } }
    @media (max-width: 1200px) {
      .footer .ccrow .cccol3 {
        order: 2; } }
    @media (max-width: 992px) {
      .footer .ccrow .cccol3 {
        order: 3; } }
  .footer .fbar {
    padding: 0px 0px 30px 0px; }
    @media (max-width: 992px) {
      .footer .fbar .logo {
        display: none; } }
    .footer .fbar .logo img {
      width: 200px; }
    .footer .fbar .logo-mobile {
      display: none; }
      @media (max-width: 992px) {
        .footer .fbar .logo-mobile {
          display: block; } }
      .footer .fbar .logo-mobile img {
        width: 200px; }
    .footer .fbar .contacts {
      font-weight: 400;
      font-size: 15px; }
      @media (max-width: 1200px) {
        .footer .fbar .contacts {
          margin: 30px 0 0 0;
          width: 100%;
          display: flex;
          justify-content: center; } }
  @media (max-width: 1200px) and (max-width: 992px) {
    .footer .fbar .contacts {
      margin: 20px 0; } }
      .footer .fbar .contacts .cgrid {
        display: flex;
        align-items: center; }
        @media (max-width: 768px) {
          .footer .fbar .contacts .cgrid {
            flex-direction: column; } }
        .footer .fbar .contacts .cgrid .ccol {
          display: flex;
          align-items: center;
          margin: 0px 18px; }
          @media (max-width: 768px) {
            .footer .fbar .contacts .cgrid .ccol {
              margin: 5px 0; } }
      .footer .fbar .contacts .ico {
        position: relative;
        top: 3px;
        color: #d0aa3a;
        margin: 0 10px 0 0; }
        .footer .fbar .contacts .ico span {
          font-size: 18px !important; }
      .footer .fbar .contacts .inf {
        color: rgba(255, 255, 255, 0.9);
        font-weight: 300; }
  .footer .sbar {
    color: rgba(255, 255, 255, 0.9);
    font-weight: 300;
    font-style: italic; }
    @media (max-width: 992px) {
      .footer .sbar {
        padding: 0px; } }
    @media (max-width: 992px) {
      .footer .sbar {
        text-align: center; } }
    .footer .sbar a {
      color: rgba(255, 255, 255, 0.9);
      text-decoration: underline !important;
      transition: 0.3s; }
    .footer .sbar a:hover {
      color: #d0aa3a;
      text-decoration: none !important; }
    .footer .sbar .gold {
      color: #d0aa3a; }
    .footer .sbar .s1 {
      float: left; }
      @media (max-width: 992px) {
        .footer .sbar .s1 {
          float: none; } }
    .footer .sbar .s2 {
      float: right; }
      @media (max-width: 992px) {
        .footer .sbar .s2 {
          float: none; } }

.section .title {
  color: #d0aa3a;
  font-size: 38px;
  font-weight: 800;
  text-transform: uppercase;
  padding: 60px 0px; }
  @media (max-width: 992px) {
    .section .title {
      font-size: 28px;
      padding: 40px 0px; } }
  .section .title span {
    color: #fff !important;
    background: #d0aa3a !important;
    font-size: 14px !important;
    padding: 8px 15px;
    font-weight: 400;
    letter-spacing: 1px; }

.section .tcenter {
  text-align: center; }

.section .tleft {
  text-align: left; }

.section .tright {
  text-align: right; }

.section .tminbot {
  padding: 50px 0px 20px 0px !important; }

.secbigbot {
  margin-bottom: 60px; }

.special {
  padding: 100px 0px; }
  .special .sptitle {
    color: #d0aa3a;
    font-size: 30px;
    font-weight: 800;
    text-transform: uppercase; }
  .special .sptext {
    color: #fff;
    font-size: 22px;
    font-weight: 300;
    margin: 20px 0 30px 0; }
    .special .sptext .bold {
      font-weight: 700; }
  .special .spbut {
    display: flex; }

.specialFooter {
  padding: 60px 0px 200px 0px !important; }

.special1 {
  padding: 60px 0px 60px 0px;
  background: url("../img/sp2bg.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; }

.special2 {
  padding: 100px 0;
  background: url("../img/sp1bg.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; }

.what-section .block {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 260px;
  border: 1px solid #d0aa3a;
  border-radius: 5px;
  padding: 15px 30px; }
  @media (max-width: 992px) {
    .what-section .block {
      width: 100% !important;
      margin: 10px 0px !important; } }
  .what-section .block .name {
    color: #d0aa3a;
    font-size: 20px;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 5px; }
  .what-section .block .info {
    color: #3d3d3d;
    font-size: 15px; }

.what-section .block-link {
  background: #d0aa3a;
  border-color: #d0aa3a;
  animation: block-link 2s ease;
  animation-iteration-count: infinite; }

@keyframes block-link {
  0% {
    box-shadow: 0px 0px 0px 0px rgba(208, 170, 58, 0.4);
    -webkit-box-shadow: 0px 0px 0px 0px rgba(208, 170, 58, 0.4);
    -moz-box-shadow: 0px 0px 0px 0px rgba(208, 170, 58, 0.4); }
  90% {
    box-shadow: 0px 0px 0px 25px rgba(208, 170, 58, 0);
    -webkit-box-shadow: 0px 0px 0px 25px rgba(208, 170, 58, 0);
    -moz-box-shadow: 0px 0px 0px 25px rgba(208, 170, 58, 0); } }
  .what-section .block-link:hover {
    transform: scale(1.05); }
  .what-section .block-link .name {
    color: #fff; }
  .what-section .block-link .info {
    color: #fff; }

.what-section .gblock {
  float: left; }

.what-section .sblock {
  float: right; }

.what-section .blockpositionleft {
  margin-left: 50px; }

.what-section .blockpositionright {
  margin-right: 50px; }

.what-section .blockspace {
  margin-bottom: 30px; }

.what-section .ringimg {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; }
  .what-section .ringimg img {
    z-index: 10; }
  @media (max-width: 992px) {
    .what-section .ringimg {
      display: none; } }

.what-section .infoalert {
  z-index: -10;
  padding: 60px 0px;
  background: #d0aa3a;
  margin-top: -10px;
  text-align: center; }
  @media (max-width: 992px) {
    .what-section .infoalert {
      margin-top: 40px;
      padding: 30px 0; } }
  .what-section .infoalert .t1 {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 30px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 120px 15px 0; }
    @media (max-width: 992px) {
      .what-section .infoalert .t1 {
        font-size: 26px;
        margin: 0; } }
    .what-section .infoalert .t1 span {
      font-size: 60px;
      margin: 0 0 0 15px; }
      @media (max-width: 992px) {
        .what-section .infoalert .t1 span {
          display: none; } }
  .what-section .infoalert .t2 {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 30px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 0 0 280px; }
    @media (max-width: 992px) {
      .what-section .infoalert .t2 {
        font-size: 26px;
        margin: 0; } }
    .what-section .infoalert .t2 span {
      font-size: 60px;
      margin: 0 15px 0 0; }
      @media (max-width: 992px) {
        .what-section .infoalert .t2 span {
          display: none; } }
  .what-section .infoalert .callback {
    margin-top: 20px; }
    @media (max-width: 992px) {
      .what-section .infoalert .callback {
        margin-top: 10px; } }
    .what-section .infoalert .callback .text {
      color: #fff;
      font-size: 20px;
      font-weight: 300;
      margin-bottom: 30px;
      text-transform: none; }
      @media (max-width: 992px) {
        .what-section .infoalert .callback .text {
          margin-bottom: 20px; } }
    .what-section .infoalert .callback__button {
      display: flex;
      justify-content: center; }

.rodium-section .rodInfo {
  color: #3d3d3d;
  font-size: 15px; }
  .rodium-section .rodInfo .rodTxt {
    padding: 20px 0px;
    font-size: 16px; }
    @media (max-width: 992px) {
      .rodium-section .rodInfo .rodTxt {
        text-align: center;
        padding: 0; }
        .rodium-section .rodInfo .rodTxt a {
          float: none; } }
    .rodium-section .rodInfo .rodTxt .rodTxtBlTitle {
      color: #d0aa3a;
      font-size: 24px;
      font-weight: 800;
      text-transform: uppercase;
      margin-bottom: 10px; }
    .rodium-section .rodInfo .rodTxt .rodTxtTitle {
      color: #d0aa3a;
      font-weight: 600;
      font-size: 16px !important; }
    .rodium-section .rodInfo .rodTxt p {
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: 400; }
    .rodium-section .rodInfo .rodTxt .rodButton {
      display: flex; }
      @media (max-width: 992px) {
        .rodium-section .rodInfo .rodTxt .rodButton {
          justify-content: center; } }
  .rodium-section .rodInfo .rodImg {
    text-align: right; }
    .rodium-section .rodInfo .rodImg img {
      width: 600px; }
      @media (max-width: 992px) {
        .rodium-section .rodInfo .rodImg img {
          width: 100%;
          margin-top: 40px; } }

.rodium-section .plusBlocks {
  margin-top: 50px;
  margin-bottom: 80px;
  margin: 50px 0 80px 0; }
  @media (max-width: 992px) {
    .rodium-section .plusBlocks {
      margin: 30px 0; } }
  .rodium-section .plusBlocks .plblTitle {
    font-size: 30px;
    font-weight: 600;
    text-transform: uppercase;
    color: #d0aa3a;
    margin-bottom: 10px; }
  .rodium-section .plusBlocks .plBlock {
    border: 1px solid #d0aa3a;
    border-radius: 5px;
    padding: 20px 25px; }
    @media (max-width: 992px) {
      .rodium-section .plusBlocks .plBlock {
        margin: 10px 0px; } }
    .rodium-section .plusBlocks .plBlock .plTitle {
      color: #d0aa3a;
      font-size: 20px;
      font-weight: 800;
      text-transform: uppercase;
      margin-bottom: 5px; }
    .rodium-section .plusBlocks .plBlock .plInfo {
      color: #3d3d3d;
      font-size: 16px; }

.rodium-section .typeRod {
  background: #d0aa3a; }
  .rodium-section .typeRod .typeRodTitle {
    color: #fff;
    font-size: 30px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 50px 0px; }
  .rodium-section .typeRod .rodType {
    margin-bottom: 25px; }
    .rodium-section .typeRod .rodType .colorRod {
      width: 25px;
      height: 25px;
      border-radius: 25px;
      float: left; }
    .rodium-section .typeRod .rodType .crtxt {
      margin-left: 15px;
      float: left; }
    .rodium-section .typeRod .rodType .crWhite {
      background: #fff; }
    .rodium-section .typeRod .rodType .crGold {
      background: #fad00e; }
    .rodium-section .typeRod .rodType .crBlack {
      background: #000;
      color: #fff;
      font-size: 19px;
      font-weight: 600;
      text-transform: uppercase; }

.wedding-section {
  padding: 120px 0;
  background: url("../img/weddbg.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; }
  .wedding-section .title {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: #fff !important;
    padding: 0 !important;
    margin: 0 0 10px 0; }
    @media (max-width: 992px) {
      .wedding-section .title {
        align-items: flex-start; } }
  .wedding-section .weddContent {
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
    @media (max-width: 992px) {
      .wedding-section .weddContent {
        align-items: flex-start; } }
    .wedding-section .weddContent__weddinfo {
      text-align: right;
      width: 600px; }
      @media (max-width: 992px) {
        .wedding-section .weddContent__weddinfo {
          width: 100%;
          text-align: left; } }
      .wedding-section .weddContent__weddinfo p {
        margin: 15px 0;
        color: #fff;
        font-size: 18px;
        font-weight: 400; }
  .wedding-section__button {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0 0 0; }

.why-section {
  padding: 0px 0px 0px 0px;
  background: url("../img/whybg.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; }
  .why-section .info {
    text-align: center;
    width: 800px;
    margin: 0 auto;
    color: #fff;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 50px; }
    @media (max-width: 992px) {
      .why-section .info {
        width: 100%; } }
  .why-section .plus {
    border: 1px solid #d0aa3a;
    border-radius: 10px;
    padding: 20px 30px;
    margin: 20px 0; }
    @media (max-width: 992px) {
      .why-section .plus {
        text-align: center; } }
    .why-section .plus .icon {
      display: flex;
      justify-content: center;
      color: #d0aa3a;
      font-size: 60px; }
      .why-section .plus .icon span {
        margin: 5px 0 0 0; }
        @media (max-width: 992px) {
          .why-section .plus .icon span {
            margin: 0; } }
    .why-section .plus .plustile {
      color: #d0aa3a;
      font-size: 20px;
      font-weight: 800;
      text-transform: uppercase;
      margin-bottom: 5px; }
    .why-section .plus .text {
      color: #fff;
      font-size: 16px;
      font-weight: 300; }

.why-wedding-section {
  padding: 0px 0px 0px 0px;
  background: url("../img/whybgwedding.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; }
  .why-wedding-section .info {
    text-align: center;
    width: 800px;
    margin: 0 auto;
    color: #000;
    font-size: 14px;
    margin-bottom: 40px; }
    @media (max-width: 992px) {
      .why-wedding-section .info {
        width: 100%; } }
  .why-wedding-section .plus {
    border: 1px solid #d0aa3a;
    border-radius: 10px;
    padding: 20px 30px;
    margin-bottom: 20px;
    color: #000;
    font-size: 14px; }
    @media (max-width: 992px) {
      .why-wedding-section .plus {
        text-align: center; } }
    .why-wedding-section .plus .icon {
      text-align: right;
      color: #d0aa3a;
      font-size: 60px; }
      @media (max-width: 992px) {
        .why-wedding-section .plus .icon {
          text-align: center; } }
    .why-wedding-section .plus .plustile {
      color: #d0aa3a;
      font-size: 19px;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 5px; }

.why-voucher-section {
  padding: 0px 0px 0px 0px;
  background: url("../img/whybgvoucher.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; }
  .why-voucher-section .info {
    text-align: center;
    width: 800px;
    margin: 0 auto;
    color: #000;
    font-size: 14px;
    margin-bottom: 40px; }
    @media (max-width: 992px) {
      .why-voucher-section .info {
        width: 100%; } }
  .why-voucher-section .plus {
    border: 1px solid #616161;
    border-radius: 10px;
    padding: 10px 20px;
    margin-bottom: 20px; }
    @media (max-width: 992px) {
      .why-voucher-section .plus {
        text-align: center; } }
    .why-voucher-section .plus .plustile {
      color: #616161;
      font-size: 19px;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 5px; }
    .why-voucher-section .plus .text {
      color: #000;
      font-size: 14px; }
  .why-voucher-section .infoalert {
    margin-top: 50px;
    padding: 40px 0px;
    background: #d0aa3a;
    text-align: center; }
    @media (max-width: 992px) {
      .why-voucher-section .infoalert {
        margin-top: 30px; } }
    .why-voucher-section .infoalert .text {
      color: #fff;
      font-size: 28px;
      font-weight: 600;
      text-transform: uppercase; }
    .why-voucher-section .infoalert .callback {
      margin-top: 30px; }
      .why-voucher-section .infoalert .callback .text {
        color: #fff;
        font-size: 20px;
        font-weight: 300;
        text-transform: uppercase;
        margin-bottom: 30px; }
      .why-voucher-section .infoalert .callback .pointer {
        position: absolute;
        width: 50px;
        z-index: 999;
        margin-top: -10px;
        left: 680px; }

.vaucher-gifts {
  margin-bottom: 50px; }
  .vaucher-gifts .giftItem .itemBlock {
    display: block;
    margin: 15px 0px;
    width: 555px;
    padding: 50px 20px;
    text-decoration: none;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    color: #fff;
    text-transform: uppercase;
    font-size: 15px;
    transition: 0.3s; }
    .vaucher-gifts .giftItem .itemBlock b {
      font-size: 26px;
      color: #d0aa3a; }
    @media (max-width: 1200px) {
      .vaucher-gifts .giftItem .itemBlock {
        width: 100%; } }
  .vaucher-gifts .giftItem .itemBlock:hover {
    background-color: rgba(0, 0, 0, 0.6); }

.catalog-section {
  padding: 0px 0px 40px 0px;
  background: url("../img/catalogbg.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; }
  .catalog-section .info {
    text-align: left;
    width: 600px;
    color: #000;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 30px; }
    @media (max-width: 992px) {
      .catalog-section .info {
        width: 60%; } }
  .catalog-section .catalogs .cat {
    margin-bottom: 30px; }
  .catalog-section .but-box {
    margin-top: 40px;
    text-align: center; }

.map {
  position: relative; }

.map-section {
  position: relative;
  z-index: 0 !important; }

.map-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  align-items: center; }
  @media (max-width: 992px) {
    .map-overlay {
      align-items: flex-end; } }
  .map-overlay__inner {
    display: flex; }
  .map-overlay .maptext {
    padding: 25px 40px;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(5px); }
    @media (max-width: 992px) {
      .map-overlay .maptext {
        padding: 25px 30px;
        margin: 0 0 50px 0;
        width: 100%;
        text-align: center; } }
    .map-overlay .maptext p {
      font-size: 16px;
      color: #fff;
      margin: 2px 0; }
    .map-overlay .maptext .gold {
      color: #d0aa3a;
      font-weight: 700;
      font-size: 20px; }

.wrapp404 {
  width: 100%;
  height: 100vh; }
  .wrapp404 .row404 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%; }
    .wrapp404 .row404 .block404 {
      text-align: center;
      max-width: 400px; }
      .wrapp404 .row404 .block404__title {
        font-size: 90px;
        line-height: 90px;
        font-weight: 800;
        color: #d0aa3a; }
      .wrapp404 .row404 .block404__text {
        font-size: 18px;
        line-height: 26px;
        font-weight: 500;
        color: #000;
        margin: 20px 0 0 0; }
      .wrapp404 .row404 .block404__button {
        display: flex;
        justify-content: center;
        margin: 25px 0 0 0; }

.content-section {
  padding: 60px 0; }
  @media (max-width: 992px) {
    .content-section {
      padding: 30px 0; } }

.services__sidebar {
  background: #f6f6f6;
  border-radius: 5px;
  padding: 0; }
  @media (max-width: 1200px) {
    .services__sidebar {
      display: none; } }
  .services__sidebar__header {
    position: relative;
    display: flex;
    flex-direction: column;
    background: #76a232;
    padding: 18px 20px 20px 20px;
    margin: 0 0 10px 0;
    border-radius: 5px; }
  .services__sidebar__title {
    display: flex;
    align-items: center;
    font-size: 22px;
    line-height: 22px;
    font-weight: 800;
    color: #fff; }
    .services__sidebar__title span {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: #fff;
      background: #d0aa3a;
      font-size: 10px;
      line-height: 10px;
      font-weight: 800;
      height: 20px;
      width: 20px;
      border-radius: 50px;
      margin: 1px 0 0 8px; }
  .services__sidebar__footer {
    display: flex;
    justify-content: center;
    text-align: center;
    background: #ececec;
    padding: 20px;
    margin: 10px 0 0 0;
    border-radius: 5px; }
  .services__sidebar__scrolldown {
    display: flex;
    align-items: center;
    text-align: center;
    color: #000;
    font-size: 12px;
    line-height: 12px;
    font-weight: 700;
    text-transform: uppercase;
    opacity: 0.4; }
    .services__sidebar__scrolldown span {
      font-size: 18px;
      line-height: 18px;
      margin: 0 5px 0 0; }
  .services__sidebar__nav-inner {
    position: relative;
    z-index: 9;
    height: 100%;
    padding: 0 10px 0 20px; }
    .services__sidebar__nav-inner::before {
      position: absolute;
      z-index: 10;
      top: 0;
      content: '';
      display: block;
      width: 87%;
      height: 20px;
      background: #f3f3f3;
      background: linear-gradient(180deg, #f6f6f6 0%, rgba(246, 246, 246, 0) 100%); }
    .services__sidebar__nav-inner::after {
      position: absolute;
      z-index: 10;
      bottom: 0;
      content: '';
      display: block;
      width: 87%;
      height: 20px;
      background: #f3f3f3;
      background: linear-gradient(0deg, #f6f6f6 0%, rgba(246, 246, 246, 0) 100%); }
  .services__sidebar__nav {
    height: 500px;
    overflow-y: scroll; }
    .services__sidebar__nav ul {
      padding: 0;
      list-style: none;
      padding: 10px 15px 0 0; }
      .services__sidebar__nav ul li {
        display: flex;
        margin: 15px 0; }
        .services__sidebar__nav ul li:first-child {
          margin: 0 0 15px 0; }
        .services__sidebar__nav ul li:last-child {
          margin: 15px 0 0 0; }
        .services__sidebar__nav ul li a {
          display: flex;
          position: relative;
          font-size: 16px;
          line-height: 24px;
          color: #000;
          text-decoration: underline;
          font-weight: 500; }

.services__content__hero {
  display: flex;
  margin: 0 0 30px 0; }
  @media (max-width: 992px) {
    .services__content__hero {
      flex-direction: column; } }
  .services__content__hero__img {
    width: 40%;
    padding: 0 30px 0 0; }
    @media (max-width: 1200px) {
      .services__content__hero__img {
        width: 50%; } }
    @media (max-width: 992px) {
      .services__content__hero__img {
        width: 100%;
        padding: 0;
        order: 2;
        margin: 20px 0 0; } }
    .services__content__hero__img img {
      width: 100%;
      border-radius: 5px;
      margin: 0 !important; }
  .services__content__hero__text {
    width: 60%;
    background: #f6f6f6;
    border-radius: 5px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    @media (max-width: 1200px) {
      .services__content__hero__text {
        width: 50%; } }
    @media (max-width: 992px) {
      .services__content__hero__text {
        width: 100%;
        order: 1;
        padding: 20px; } }

.services__content h2 {
  font-size: 22px;
  line-height: 32px;
  font-weight: 800;
  margin: 0 0 8px 0;
  color: #d0aa3a; }

.services__content p {
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  margin: 8px 0; }
  .services__content p b {
    font-weight: 600; }

.services__content br {
  display: block;
  height: 14px;
  content: ""; }

.services__content img {
  width: 100%;
  border-radius: 5px;
  margin: 30px 0; }
  @media (max-width: 992px) {
    .services__content img {
      margin: 20px 0; } }

.services__content__callback {
  background: #f6f6f6;
  border-radius: 5px;
  padding: 60px 80px;
  margin: 30px 0 0 0; }
  @media (max-width: 992px) {
    .services__content__callback {
      padding: 30px; } }
  .services__content__callback__title {
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    font-weight: 800;
    color: #d0aa3a; }
  .services__content__callback__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0 0 0; }
    @media (max-width: 992px) {
      .services__content__callback__buttons {
        flex-direction: column; } }
    .services__content__callback__buttons__separator {
      font-size: 14px;
      line-height: 14px;
      font-weight: 800;
      text-transform: uppercase;
      margin: 0 30px;
      opacity: 0.2; }
      @media (max-width: 992px) {
        .services__content__callback__buttons__separator {
          margin: 15px 0; } }
