.popup {
    position: fixed;
    z-index: 99999999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    backdrop-filter: blur(10px);
    background: rgba(0, 0, 0, 0.4);

    @media (max-width: 992px) {
        background: $white; }

    &__close-but {
        position: absolute;
        top: 30px;
        right: 30px;

        @media (max-width: 992px) {
            top: 0;
            right: 0; }

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            background: $white;
            padding: 15px;
            border-radius: 5px;
            color: $black;
            font-size: 18px;
            line-height: 18px;

            @media (max-width: 992px) {
                font-size: 24px;
                line-height: 24px; }

            &:hover {
                background: $gold;
                color: $white;

                @media (max-width: 992px) {
                    background: $white;
                    color: $black; } } } }

    &__inner {
        background: $white;
        border-radius: 15px;
        padding: 60px 80px;

        @media (max-width: 992px) {
            padding: 30px;
            border-radius: 0; } } }

.callback-popup {

    &__inner {
        width: 600px;

        @media (max-width: 992px) {
            width: 100%; } }

    &__content {}

    &__title {
        font-size: 38px;
        line-height: 40px;
        font-weight: 800;
        color: $gold;
        text-align: center;
        margin: 0 0 20px 0;

        @media (max-width: 992px) {
            font-size: 28px;
            line-height: 30px;
            margin: 0 0 10px 0; } }

    &__form {

        &__field {
            width: 100%;
            margin: 10px 0;

            button {
                width: 100%;
                margin: 40px 0 0 0;

                @media (max-width: 992px) {
                    margin: 20px 0 0 0; } } } }

    &__agreement {
        text-align: center;
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.4);
        margin: 20px 0 0 0;

        @media (max-width: 992px) {
            margin: 15px 0 0 0; }

        a {
            color: rgba(0, 0, 0, 0.4);
            text-decoration: underline;

            &:hover {
                color: $gold; } } } }
