//FOOTER
.footer {
    position: relative;
    width: 100%;
    z-index: 10;
    padding: 40px 0px;
    background: $grey2;

    .ccrow {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 1200px) {
            flex-wrap: wrap; }

        @media (max-width: 992px) {
            justify-content: center; }

        .cccol1 {

            @media (max-width: 1200px) {
                order: 1; }

            @media (max-width: 992px) {
                order: 1; } }

        .cccol2 {

            @media (max-width: 1200px) {
                width: 100%;
                order: 3; }

            @media (max-width: 992px) {
                order: 2; } }

        .cccol3 {

            @media (max-width: 1200px) {
                order: 2; }

            @media (max-width: 992px) {
                order: 3; } } }

    .fbar {
        padding: 0px 0px 30px 0px;

        .logo {

            @media (max-width: 992px) {
                display: none; }

            img {
                width: 200px; } }

        .logo-mobile {
            display: none;

            @media (max-width: 992px) {
                display: block; }

            img {
                width: 200px; } }


        .contacts {
            font-weight: 400;
            font-size: 15px;

            @media (max-width: 1200px) {
                margin: 30px 0 0 0;
                width: 100%;
                display: flex;
                justify-content: center;

                @media (max-width: 992px) {
                    margin: 20px 0; } }

            .cgrid {
                display: flex;
                align-items: center;

                @media (max-width: 768px) {
                    flex-direction: column; }

                .ccol {
                    display: flex;
                    align-items: center;
                    margin: 0px 18px;

                    @media (max-width: 768px) {
                        margin: 5px 0; } } }

            .ico {
                position: relative;
                top: 3px;
                color: $gold;
                margin: 0 10px 0 0;

                span {
                    font-size: 18px !important; } }

            .inf {
                color: $white-alpha;
                font-weight: $thin; } }

        .callbutton {} }

    .sbar {
        color: $white-alpha;
        font-weight: $thin;
        font-style: italic;

        @media (max-width: 992px) {
            padding: 0px; }

        @media (max-width: 992px) {
            text-align: center; }

        a {
            color: $white-alpha;
            text-decoration: underline !important;
            transition: 0.3s; }

        a:hover {
            color: $gold;
            text-decoration: none !important; }

        .gold {
            color: $gold; }

        .s1 {
            float: left;

            @media (max-width: 992px) {
                float: none; } }

        .s2 {
            float: right;

            @media (max-width: 992px) {
                float: none; } } } }
