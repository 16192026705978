//SECTIONS
.section {

    .title {
        color: $gold;
        font-size: 38px;
        font-weight: 800;
        text-transform: uppercase;
        padding: 60px 0px;

        @media (max-width: 992px) {
            font-size: 28px;
            padding: 40px 0px; }

        span {
            color: $white !important;
            background: $gold !important;
            font-size: 14px !important;
            padding: 8px 15px;
            font-weight: 400;
            letter-spacing: 1px; } }

    .tcenter {
        text-align: center; }

    .tleft {
        text-align: left; }

    .tright {
        text-align: right; }

    .tminbot {
        padding: 50px 0px 20px 0px !important; } }

.secbigbot {
    margin-bottom: 60px; }

//SPECIAL
.special {
    padding: 100px 0px;

    .sptitle {
        color: $gold;
        font-size: 30px;
        font-weight: 800;
        text-transform: uppercase; }

    .sptext {
        color: $white;
        font-size: 22px;
        font-weight: 300;
        margin: 20px 0 30px 0;

        .bold {
            font-weight: $bold; } }

    .spbut {
        display: flex; } }

.specialFooter {
    padding: 60px 0px 200px 0px !important; }

.special1 {
    padding: 60px 0px 60px 0px;
    background: url('../img/sp2bg.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover; }

.special2 {
    padding: 100px 0;
    background: url('../img/sp1bg.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover; }

//WHAT
.what-section {

    .block {
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 260px;
        border: 1px solid $gold;
        border-radius: 5px;
        padding: 15px 30px;

        @media (max-width: 992px) {
            width: 100% !important;
            margin: 10px 0px !important; }

        .name {
            color: $gold;
            font-size: 20px;
            font-weight: 800;
            text-transform: uppercase;
            margin-bottom: 5px; }

        .info {
            color: $grey;
            font-size: 15px; } }

    .block-link {
        background: $gold;
        border-color: $gold;

        animation: block-link 2s ease;
        animation-iteration-count: infinite;

        @keyframes block-link {
            0% {
                box-shadow: 0px 0px 0px 0px rgba(208,170,58,0.4);
                -webkit-box-shadow: 0px 0px 0px 0px rgba(208,170,58,0.4);
                -moz-box-shadow: 0px 0px 0px 0px rgba(208,170,58,0.4); }

            90% {
                box-shadow: 0px 0px 0px 25px rgba(208,170,58,0);
                -webkit-box-shadow: 0px 0px 0px 25px rgba(208,170,58,0);
                -moz-box-shadow: 0px 0px 0px 25px rgba(208,170,58,0); } }

        &:hover {
            transform: scale(1.05); }

        .name {
            color: $white; }

        .info {
            color: $white; } }

    .gblock {
        float: left; }

    .sblock {
        float: right; }

    .blockpositionleft {
        margin-left: 50px; }

    .blockpositionright {
        margin-right: 50px; }

    .blockspace {
        margin-bottom: 30px; }

    .ringimg {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        img {
            z-index: 10; }

        @media (max-width: 992px) {
            display: none; } }

    .infoalert {
        z-index: -10;
        padding: 60px 0px;
        background: $gold;
        margin-top: -10px;
        text-align: center;

        @media (max-width: 992px) {
            margin-top: 40px;
            padding: 30px 0; }

        .t1 {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $white;
            font-size: 30px;
            font-weight: 600;
            text-transform: uppercase;
            margin: 0 120px 15px 0;

            @media (max-width: 992px) {
                font-size: 26px;
                margin: 0; }

            span {
                font-size: 60px;
                margin: 0 0 0 15px;

                @media (max-width: 992px) {
                    display: none; } } }

        .t2 {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $white;
            font-size: 30px;
            font-weight: 600;
            text-transform: uppercase;
            margin: 0 0 0 280px;

            @media (max-width: 992px) {
                font-size: 26px;
                margin: 0; }

            span {
                font-size: 60px;
                margin: 0 15px 0 0;

                @media (max-width: 992px) {
                    display: none; } } }

        .callback {
            margin-top: 20px;

            @media (max-width: 992px) {
                margin-top: 10px; }

            .text {
                color: $white;
                font-size: 20px;
                font-weight: $thin;
                margin-bottom: 30px;
                text-transform: none;

                @media (max-width: 992px) {
                    margin-bottom: 20px; } }

            &__button {
                display: flex;
                justify-content: center; } } } }

//RODIUM
.rodium-section {

    .rodInfo {
        color: $grey;
        font-size: 15px;

        .rodTxt {
            padding: 20px 0px;
            font-size: 16px;

            @media (max-width: 992px) {
                text-align: center;
                padding: 0;

                a {
                    float: none; } }

            .rodTxtBlTitle {
                color: $gold;
                font-size: 24px;
                font-weight: 800;
                text-transform: uppercase;
                margin-bottom: 10px; }

            .rodTxtTitle {
                color: $gold;
                font-weight: 600;
                font-size: 16px !important; }

            p {
                margin-bottom: 20px;
                font-size: 16px;
                font-weight: 400; }

            .rodButton {
                display: flex;

                @media (max-width: 992px) {
                    justify-content: center; } } }

        .rodImg {
            text-align: right;

            img {
                width: 600px;

                @media (max-width: 992px) {
                    width: 100%;
                    margin-top: 40px; } } } }

    .plusBlocks {
        margin-top: 50px;
        margin-bottom: 80px;

        margin: 50px 0 80px 0;

        @media (max-width: 992px) {
            margin: 30px 0; }

        .plblTitle {
            text-align:center {}
            font-size: 30px;
            font-weight: 600;
            text-transform: uppercase;
            color: $gold;
            margin-bottom: 10px; }

        .plBlock {
            border: 1px solid $gold;
            border-radius: 5px;
            padding: 20px 25px;

            @media (max-width: 992px) {
                margin: 10px 0px; }

            .plTitle {
                color: $gold;
                font-size: 20px;
                font-weight: 800;
                text-transform: uppercase;
                margin-bottom: 5px; }

            .plInfo {
                color: $grey;
                font-size: 16px; } } }

    .typeRod {
        background: $gold;

        .typeRodTitle {
            color: $white;
            font-size: 30px;
            font-weight: $sbold;
            text-transform: uppercase;
            padding: 50px 0px; }

        .rodType {
            margin-bottom: 25px;

            .colorRod {
                width: 25px;
                height: 25px;
                border-radius: 25px;
                float: left; }

            .crtxt {
                margin-left: 15px;
                float: left; }

            .crWhite {
                background: $white; }

            .crGold {
                background: #fad00e; }

            .crBlack {
                background: $black;
                color: $white;
                font-size: 19px;
                font-weight: 600;
                text-transform: uppercase; } } } }

//WEDDING
.wedding-section {
    padding: 120px 0;
    background: url('../img/weddbg.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    .title {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        color: $white !important;
        padding: 0 !important;
        margin: 0 0 10px 0;

        @media (max-width: 992px) {
            align-items: flex-start; } }

    .weddContent {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @media (max-width: 992px) {
            align-items: flex-start; }

        &__weddinfo {
            text-align: right;
            width: 600px;

            @media (max-width: 992px) {
                width: 100%;
                text-align: left; }

            p {
                margin: 15px 0;
                color: $white;
                font-size: 18px;
                font-weight: 400; } } }

    &__button {
        display: flex;
        justify-content: flex-end;
        margin: 20px 0 0 0; } }

//WHY
.why-section {
    padding: 0px 0px 0px 0px;
    background: url('../img/whybg.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    .info {
        text-align: center;
        width: 800px;
        margin: 0 auto;
        color: $white;
        font-size: 16px;
        font-weight: 300;
        margin-bottom: 50px;

        @media (max-width: 992px) {
            width: 100%; } }

    .plus {
        border: 1px solid $gold;
        border-radius: 10px;
        padding: 20px 30px;
        margin: 20px 0;

        @media (max-width: 992px) {
            text-align: center; }


        .icon {
            display: flex;
            justify-content: center;
            color: $gold;
            font-size: 60px;

            span {
                margin: 5px 0 0 0;

                @media (max-width: 992px) {
                    margin: 0; } } }

        .plustile {
            color: $gold;
            font-size: 20px;
            font-weight: 800;
            text-transform: uppercase;
            margin-bottom: 5px; }

        .text {
            color: $white;
            font-size: 16px;
            font-weight: 300; } } }


//WHY WEDDING
.why-wedding-section {
    padding: 0px 0px 0px 0px;
    background: url('../img/whybgwedding.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    .info {
        text-align: center;
        width: 800px;
        margin: 0 auto;
        color: $black;
        font-size: 14px;
        margin-bottom: 40px;

        @media (max-width: 992px) {
            width: 100%; } }

    .plus {
        border: 1px solid $gold;
        border-radius: 10px;
        padding: 20px 30px;
        margin-bottom: 20px;

        @media (max-width: 992px) {
            text-align: center; }


        .icon {
            text-align: right;
            color: $gold;
            font-size: 60px;

            @media (max-width: 992px) {
                text-align: center; } }

        .plustile {
            color: $gold;
            font-size: 19px;
            font-weight: $sbold;
            text-transform: uppercase;
            margin-bottom: 5px; }

        .text {}
        color: $black;
        font-size: 14px; } }

//WHY VOUCHER
.why-voucher-section {
    padding: 0px 0px 0px 0px;
    background: url('../img/whybgvoucher.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    .info {
        text-align: center;
        width: 800px;
        margin: 0 auto;
        color: $black;
        font-size: 14px;
        margin-bottom: 40px;

        @media (max-width: 992px) {
            width: 100%; } }

    .plus {
        border: 1px solid $silver;
        border-radius: 10px;
        padding: 10px 20px;
        margin-bottom: 20px;

        @media (max-width: 992px) {
            text-align: center; }


        .plustile {
            color: $silver;
            font-size: 19px;
            font-weight: $sbold;
            text-transform: uppercase;
            margin-bottom: 5px; }

        .text {
            color: $black;
            font-size: 14px; } }

    .infoalert {
        margin-top: 50px;
        padding: 40px 0px;
        background: $gold;
        text-align: center;

        @media (max-width: 992px) {
            margin-top: 30px; }

        .text {
            color: $white;
            font-size: 28px;
            font-weight: $sbold;
            text-transform: uppercase; }

        .callback {
            margin-top: 30px;

            .text {
            	color: $white;
            	font-size: 20px;
            	font-weight: $thin;
            	text-transform: uppercase;
            	margin-bottom: 30px; }

            .pointer {
                position: absolute;
                width: 50px;
                z-index: 999;
                margin-top: -10px;
                left: 680px; } } } }

.vaucher-gifts {
    margin-bottom: 50px;

    .giftItem {

        .itemBlock {
            display: block;
            margin: 15px 0px;
            width: 555px;
            padding: 50px 20px;
            text-decoration: none;
            background-position: center center;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
            color: $white;
            text-transform: uppercase;
            font-size: 15px;
            transition: 0.3s;

            b {
                font-size: 26px;
                color: $gold; }

            @media (max-width: 1200px) {
                width: 100%; } }

        .itemBlock:hover {
            background-color: rgba(0, 0, 0, 0.6); } } }

//CATALOG
.catalog-section {
    padding: 0px 0px 40px 0px;
    background: url('../img/catalogbg.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    .info {
        text-align: left;
        width: 600px;
        color: $black;
        font-size: 14px;
        font-weight: $thin;
        margin-bottom: 30px;

        @media (max-width: 992px) {
            width: 60%; } }

    .catalogs {

        .cat {
            margin-bottom: 30px; } }

    .but-box {
        margin-top: 40px;
        text-align: center; } }

//COMMENT
.portfolio-section {}

//MAP
.map {
    position: relative; }

.map-section {
    position: relative;
    z-index: 0 !important; }

.map-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    align-items: center;

    @media (max-width: 992px) {
        align-items: flex-end; }

    &__inner {
        display: flex; }

    .maptext {
        padding: 25px 40px;
        background: rgba(0, 0, 0, 0.7);
        backdrop-filter: blur(5px);

        @media (max-width: 992px) {
            padding: 25px 30px;
            margin: 0 0 50px 0;
            width: 100%;
            text-align: center; }

        p {
            font-size: 16px;
            color: $white;
            margin: 2px 0; }

        .gold {
            color: $gold;
            font-weight: $bold;
            font-size: 20px; } } }
