.scroll-wrapper {
    overflow: hidden !important;
    padding: 0 !important;
    position: relative;

    & > .scroll-content {
        border: none !important;
        box-sizing: content-box !important;
        height: auto;
        left: 0;
        margin: 0;
        max-height: none;
        max-width: none !important;
        overflow: scroll !important;
        padding: 0;
        position: relative !important;
        top: 0;
        width: auto !important;

        &::-webkit-scrollbar {
            height: 0;
            width: 0; } }

    &.scroll--rtl {
        direction: rtl; } }

.scroll-element {
    box-sizing: content-box;
    display: none;

    div {
        box-sizing: content-box; }

    .scroll-bar,
    .scroll-arrow {
        cursor: default; }

    &.scroll-x.scroll-scrollx_visible,
    &.scroll-y.scroll-scrolly_visible {
        display: block; } }

.scroll-textarea {
    border: 1px solid #cccccc;
    border-top-color: #999999;

    & > .scroll-content {
        overflow: hidden !important;

        & > textarea {
            border: none !important;
            box-sizing: border-box;
            height: 100% !important;
            margin: 0;
            max-height: none !important;
            max-width: none !important;
            overflow: scroll !important;
            outline: none;
            padding: 2px;
            position: relative !important;
            top: 0;
            width: 100% !important;

            &::-webkit-scrollbar {
                height: 0;
                width: 0; } } } }


.scrollbar-inner > .scroll-element,
.scrollbar-inner > .scroll-element div {
    border: none;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 10; }

.scrollbar-inner > .scroll-element div {
    display: block;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%; }

.scrollbar-inner > .scroll-element.scroll-x {
    bottom: 2px;
    height: 8px;
    left: 0;
    width: 100%; }

.scrollbar-inner > .scroll-element.scroll-y {
    height: 100%;
    right: 2px;
    top: 0;
    width: 8px; }

.scrollbar-inner > .scroll-element .scroll-element_outer {
    overflow: hidden; }

.scrollbar-inner > .scroll-element .scroll-element_outer,
.scrollbar-inner > .scroll-element .scroll-element_track,
.scrollbar-inner > .scroll-element .scroll-bar {
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px; }

.scrollbar-inner > .scroll-element .scroll-element_track,
.scrollbar-inner > .scroll-element .scroll-bar {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
    filter: alpha(opacity=40);
    opacity: 0.4; }

.scrollbar-inner > .scroll-element .scroll-element_track {
    background-color: #e0e0e0; }

.scrollbar-inner > .scroll-element .scroll-bar {
    background-color: #c2c2c2; }

.scrollbar-inner > .scroll-element:hover .scroll-bar {
    background-color: #919191; }

.scrollbar-inner > .scroll-element.scroll-draggable .scroll-bar {
    background-color: #919191; }

.scrollbar-inner > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track {
    left: -12px; }

.scrollbar-inner > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track {
    top: -12px; }

.scrollbar-inner > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size {
    left: -12px; }

.scrollbar-inner > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size {
    top: -12px; }




.scrollbar-outer > .scroll-element,
.scrollbar-outer > .scroll-element div {
    border: none;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 10; }

.scrollbar-outer > .scroll-element {
    background-color: #ffffff; }

.scrollbar-outer > .scroll-element div {
    display: block;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%; }

.scrollbar-outer > .scroll-element.scroll-x {
    bottom: 0;
    height: 12px;
    left: 0;
    width: 100%; }

.scrollbar-outer > .scroll-element.scroll-y {
    height: 100%;
    right: 0;
    top: 0;
    width: 12px; }

.scrollbar-outer > .scroll-element.scroll-x .scroll-element_outer {
    height: 8px;
    top: 2px; }

.scrollbar-outer > .scroll-element.scroll-y .scroll-element_outer {
    left: 2px;
    width: 8px; }

.scrollbar-outer > .scroll-element .scroll-element_outer {
    overflow: hidden; }

.scrollbar-outer > .scroll-element .scroll-element_track {
    background-color: #eeeeee; }

.scrollbar-outer > .scroll-element .scroll-element_outer,
.scrollbar-outer > .scroll-element .scroll-element_track,
.scrollbar-outer > .scroll-element .scroll-bar {
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px; }

.scrollbar-outer > .scroll-element .scroll-bar {
    background-color: #d9d9d9; }

.scrollbar-outer > .scroll-element .scroll-bar:hover {
    background-color: #c2c2c2; }

.scrollbar-outer > .scroll-element.scroll-draggable .scroll-bar {
    background-color: #919191; }


.scrollbar-outer > .scroll-content.scroll-scrolly_visible {
    left: -12px;
    margin-left: 12px; }

.scrollbar-outer > .scroll-content.scroll-scrollx_visible {
    top: -12px;
    margin-top: 12px; }

.scrollbar-outer > .scroll-element.scroll-x .scroll-bar {
    min-width: 10px; }

.scrollbar-outer > .scroll-element.scroll-y .scroll-bar {
    min-height: 10px; }

.scrollbar-outer > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track {
    left: -14px; }

.scrollbar-outer > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track {
    top: -14px; }

.scrollbar-outer > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size {
    left: -14px; }

.scrollbar-outer > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size {
    top: -14px; }


.scrollbar-dynamic > .scroll-element,
.scrollbar-dynamic > .scroll-element div {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 10; }

.scrollbar-dynamic > .scroll-element div {
    display: block;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%; }

.scrollbar-dynamic > .scroll-element.scroll-x {
    bottom: 2px;
    height: 7px;
    left: 0;
    min-width: 100%;
    width: 100%; }

.scrollbar-dynamic > .scroll-element.scroll-y {
    height: 100%;
    min-height: 100%;
    right: 2px;
    top: 0;
    width: 7px; }

.scrollbar-dynamic > .scroll-element .scroll-element_outer {
    opacity: 0.3;

    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px; }

.scrollbar-dynamic > .scroll-element .scroll-element_size {
    background-color: #cccccc;
    opacity: 0;

    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;

    -webkit-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    -ms-transition: opacity 0.2s;
    transition: opacity 0.2s; }

.scrollbar-dynamic > .scroll-element .scroll-bar {
    background-color: #6c6e71;

    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px; }

.scrollbar-dynamic > .scroll-element.scroll-x .scroll-bar {
    bottom: 0;
    height: 3px;
    min-width: 24px;
    top: auto; }

.scrollbar-dynamic > .scroll-element.scroll-y .scroll-bar {
    left: auto;
    min-height: 24px;
    right: 0;
    width: 3px; }

.scrollbar-dynamic > .scroll-element.scroll-x .scroll-element_outer {
    bottom: 0;
    top: auto;
    left: 2px;

    -webkit-transition: height 0.2s;
    -moz-transition: height 0.2s;
    -o-transition: height 0.2s;
    -ms-transition: height 0.2s;
    transition: height 0.2s; }

.scrollbar-dynamic > .scroll-element.scroll-y .scroll-element_outer {
    left: auto;
    right: 0;
    top: 2px;

    -webkit-transition: width 0.2s;
    -moz-transition: width 0.2s;
    -o-transition: width 0.2s;
    -ms-transition: width 0.2s;
    transition: width 0.2s; }

.scrollbar-dynamic > .scroll-element.scroll-x .scroll-element_size {
    left: -4px; }

.scrollbar-dynamic > .scroll-element.scroll-y .scroll-element_size {
    top: -4px; }

.scrollbar-dynamic > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size {
    left: -11px; }

.scrollbar-dynamic > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size {
    top: -11px; }

.scrollbar-dynamic > .scroll-element:hover .scroll-element_outer,
.scrollbar-dynamic > .scroll-element.scroll-draggable .scroll-element_outer {
    overflow: hidden;

    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
    filter: alpha(opacity=70);
    opacity: 0.7; }

.scrollbar-dynamic > .scroll-element:hover .scroll-element_outer .scroll-element_size,
.scrollbar-dynamic > .scroll-element.scroll-draggable .scroll-element_outer .scroll-element_size {
    opacity: 1; }

.scrollbar-dynamic > .scroll-element:hover .scroll-element_outer .scroll-bar,
.scrollbar-dynamic > .scroll-element.scroll-draggable .scroll-element_outer .scroll-bar {
    height: 100%;
    width: 100%;

    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px; }

.scrollbar-dynamic > .scroll-element.scroll-x:hover .scroll-element_outer,
.scrollbar-dynamic > .scroll-element.scroll-x.scroll-draggable .scroll-element_outer {
    height: 8px;
    min-height: 7px; }

.scrollbar-dynamic > .scroll-element.scroll-y:hover .scroll-element_outer,
.scrollbar-dynamic > .scroll-element.scroll-y.scroll-draggable .scroll-element_outer {
    min-width: 7px;
    width: 8px; }
