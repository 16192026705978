input[type=text],
input[type=time],
input[type=date] {
    border: none;
    border-bottom: 1px solid $gold;
    padding: 20px 0;
    background: $white;
    color: $black;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.6px;
    background: transparent;
    @include transit;
    width: 100%;
    -webkit-appearance: none;

    @media (max-width: 992px) {
        padding: 16px 0;
        font-size: 16px;
        line-height: 16px; }

    &:hover {}

    &:active {}

    &:focus {
        outline: none !important; } }
