.wrapp404 {
    width: 100%;
    height: 100vh;

    .row404 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        .block404 {
            text-align: center;
            max-width: 400px;

            &__title {
                font-size: 90px;
                line-height: 90px;
                font-weight: 800;
                color: $gold; }

            &__text {
                font-size: 18px;
                line-height: 26px;
                font-weight: 500;
                color: $black;
                margin: 20px 0 0 0; }

            &__button {
                display: flex;
                justify-content: center;
                margin: 25px 0 0 0; } } } }
