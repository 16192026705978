.header {

	@media (max-width: 1200px) {
		display: none; }

	.ccrow {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.cccol1 {}

		.cccol2 {}

		.cccol3 {} }

	.fbar {
		padding: 35px 0px;
		border-bottom: 1px solid $white-alpha2;

		.logo {

			img {
				width: 210px; } }

		.contacts {
			font-weight: 400;
			font-size: 15px;

			.cgrid {
				display: flex;
				align-items: center;

				.ccol {
					display: flex;
					align-items: center;
					margin: 0px 18px; } }


			.ico {
				position: relative;
				top: 3px;
				color: $gold;
				margin: 0 10px 0 0;

				span {
					font-size: 18px !important; } }

			.inf {
				color: $white-alpha;
				font-weight: $thin; } }

		.callbutton {} }

	.sbar {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 15px 0px;

		.navigation {
			float: left;

			ul {
				display: flex;
				margin: 0;
				padding: 0;
				list-style-type: none;

				li {
					margin: 0 22px 0 0;

					a {
						display: flex;
						position: relative;
						font-size: 16px;
						color: $white-alpha;
						transition: 0.3s;
						font-weight: $thin;

						span {
							position: absolute;
							right: -14px;
							top: -3px;
							display: flex;
							justify-content: center;
							align-items: center;
							text-align: center;
							color: $white;
							background: $red;
							font-size: 9px;
							line-height: 9px;
							font-weight: 700;
							height: 17px;
							width: 17px;
							border-radius: 50px; } }


					a:hover {
						text-decoration: none;
						color: $gold;
						border-color: $gold;

						span {
							color: $white; } } } } }

		.social {

			ul {
				display: flex;
				align-items: center;
				margin: 0;
				padding: 0;
				list-style-type: none;

				li {
					margin: 0 0 0 20px;

					a {
						display: flex;
						align-items: center;
						justify-content: center;
						text-align: center;
						font-size: 18px;
						color: $white-alpha;
						transition: 0.3s; }

					a:hover {
						color: $gold;
						text-decoration: none; } } } } } }







.headerMobile {
	position: sticky;
	top: 0;
	display: none;
	background: $white;
	z-index: 999999;
	box-shadow: -2px 2px 15px 5px rgba(0,0,0,0.15);
	-webkit-box-shadow: -2px 2px 15px 5px rgba(0,0,0,0.15);
	-moz-box-shadow: -2px 2px 15px 5px rgba(0,0,0,0.15);

	&_nav-open {
		box-shadow: none;
		-webkit-box-shadow: none;
		-moz-box-shadow: none; }

	@media (max-width: 1200px) {
		display: block; }

	&__inner {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;
		padding: 20px 0; }

	.logo {

		img {
			height: 22px; } }

	.mobileNavButton {
		position: relative;

		&__open {
			position: relative;
			z-index: 9999998; }

		&__close {
			position: absolute;
			top: -3px;
			left: -2px;
			z-index: 9999999;
			background: $white;
			padding: 5px;
			font-size: 20px !important; }

		a {
			display: flex;
			align-items: center;
			justify-content: center;
			text-decoration: none;
			font-size: 24px;
			color: $gold; } } }

.mobile-navigation {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 99999;
	background: $white;

	padding: 70px 15px 30px 15px;

	&__nav {
		flex-grow: 1;
		display: flex;
		align-items: center;
		justify-content: center;

		ul {
			padding: 0;
			list-style: none;

			li {
				display: flex;
				justify-content: center;
				margin: 0 0 12px 0;

				a {
					display: flex;
					position: relative;
					font-size: 18px;
					color: $black;
					transition: 0.3s;
					font-weight: 600;

					span {
						position: absolute;
						right: -20px;
						top: 0px;
						display: flex;
						justify-content: center;
						align-items: center;
						text-align: center;
						color: $white;
						background: $red;
						font-size: 9px;
						line-height: 9px;
						font-weight: 700;
						height: 17px;
						width: 17px;
						border-radius: 50px; } } } } }

	&__contacts {
		margin: 0 0 15px 0;

		ul {
			padding: 0;
			list-style: none;

			li {
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 16px;
				line-height: 16px;
				font-weight: 500;
				color: $black;
				margin: 15px 0 0 0;

				span {
					position: relative;
					top: 1px;
					color: $gold;
					font-size: 20px;
					line-height: 20px;
					margin: 0 8px 0 0; }

				a {
					display: flex;
					align-items: center;
					color: $black;
					font-weight: 500;

					span {
						position: relative;
						top: 1px;
						color: $gold;
						font-size: 20px;
						line-height: 20px;
						margin: 0 8px 0 0; } } } } }

	&__callback {
		display: flex;
		justify-content: center; }

	&__categories {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $white;
		z-index: 999999;
		padding: 70px 15px 15px 15px;
		display: flex;
		flex-direction: column;

		&__header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin: 10px 0 20px 0;
			padding: 0 0 20px 0;
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);

			&__back {

				a {
					display: flex;
					align-items: center;
					font-size: 14px;
					line-height: 14px;
					font-weight: 800;
					color: $black;
					text-transform: uppercase;

					span {
						margin: 0 5px 0 0; } } }

			&__title {
				position: relative;
				font-size: 20px;
				line-height: 20px;
				font-weight: 800;
				color: $black;

				span {
					position: absolute;
					right: -20px;
					top: 0px;
					display: flex;
					justify-content: center;
					align-items: center;
					text-align: center;
					color: $white;
					background: $red;
					font-size: 9px;
					line-height: 9px;
					font-weight: 700;
					height: 17px;
					width: 17px;
					border-radius: 50px; } } }

		&__nav {
			height: 100%;
			overflow-x: scroll;

			ul {
				padding: 0;
				list-style: none;

				li {
					display: flex;
					justify-content: center;
					margin: 20px 0;

					&:first-child {
						margin: 0 0 20px 0; }

					&:last-child {
						margin: 20px 0 0 0; }

					a {
						display: flex;
						position: relative;
						font-size: 16px;
						line-height: 20px;
						color: $black;
						text-decoration: underline;
						font-weight: 500;
						text-align: center;
						padding: 0 20px; } } } } } }
