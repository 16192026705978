@font-face {
    font-family: 'icons';
    src:  url('../icons/icons.eot?chssw7'), url('../icons/icons.eot?chssw7#iefix') format('embedded-opentype'), url('../icons/icons.ttf?chssw7') format('truetype'), url('../icons/icons.woff?chssw7') format('woff'), url('../icons/icons.svg?chssw7#icons') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block; }

[class^="icon-"] {
    font-family: 'icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

[class*=" icon-"] {
    font-family: 'icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.icon-ag-au:before {
	content: "\E001"; }

.icon-ag:before {
	content: "\E002"; }

.icon-arrow:before {
	content: "\E003"; }

.icon-au:before {
	content: "\E004"; }

.icon-clock:before {
	content: "\E005"; }

.icon-cross:before {
	content: "\E006"; }

.icon-diamond:before {
	content: "\E007"; }

.icon-facebook:before {
	content: "\E008"; }

.icon-gold:before {
	content: "\E009"; }

.icon-instagram:before {
	content: "\E00A"; }

.icon-location:before {
	content: "\E00B"; }

.icon-love:before {
	content: "\E00C"; }

.icon-navigation:before {
	content: "\E00D"; }

.icon-odnoklassniki:before {
	content: "\E00E"; }

.icon-phone:before {
	content: "\E00F"; }

.icon-plus:before {
	content: "\E010"; }

.icon-quality:before {
	content: "\E011"; }

.icon-scrolldown:before {
	content: "\E012"; }

.icon-telegram:before {
	content: "\E013"; }

.icon-tik-tok:before {
	content: "\E014"; }

.icon-vk:before {
	content: "\E015"; }

.icon-ya-zen:before {
	content: "\E016"; }

.icon-youtube:before {
	content: "\E017"; }

