@mixin transit($when: 0.3s, $what: all, $how: ease) {
  -webkit-transition: $what $when $how;
  -moz-transition: $what $when $how;
  -ms-transition: $what $when $how;
  -o-transition: $what $when $how;
  transition: $what $when $how; }

//bg-cover

@mixin bg-cover {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover; }

@mixin bg-cover-top {
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover; }

@mixin bg-cover-bottom {
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover; }
