.button {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	cursor: pointer;
	padding: 10px 25px 10px 25px;
	border: 1px solid;
	border-radius: 3px;

	font-size: 16px;
	font-weight: 400;
	letter-spacing: 0.6px;
	text-transform: uppercase;

	text-decoration: none;
	outline: none;

	transition: 0.3s;

	.nxtIcon {
		position: relative;
		font-size: 18px;
		margin: 0 8px 0 0; } }

.button-big {
	padding: 20px 25px;

	@media (max-width: 992px) {
		padding: 15px 25px; } }

.button:active {
	outline: none;
	text-decoration: none; }

.button:focus {
	text-decoration: none;
	outline: none; }

.button:hover {
	text-decoration: none; }

.bgold {
	color: $gold;
	border-color: $gold;

	&:hover {
		color: $white-alpha;
		background: $gold; } }

.bgold-filled {
	color: $white;
	border-color: $gold;
	background: $gold;

	&:hover {
		color: $white-alpha;
		background: $gold-hover; } }

.bwhite {
	color: $white;
	border-color: $white;

	&:hover {
		color: $gold;
		background: $white; } }

.bwhite-filled {
	color: $gold;
	border-color: $white;
	background: $white;

	&:hover {
		color: $white;
		background: $silver;
		border-color: $silver; } }

.green-filled {
	color: $white;
	background: $green;
	border-color: $green;

	&:hover {
		color: $white;
		background: $green-hover;
		border-color: $green-hover; } }

.green-filled-2 {
	color: $white;
	background: $green-2;
	border-color: $green-2;

	&:hover {
		color: $white;
		background: $green-2-hover;
		border-color: $green-2-hover; } }

.blue-filled {
	color: $white;
	border-color: $blue;
	background: $blue;

	&:hover {
		color: $white;
		background: $blue-hover; } }
