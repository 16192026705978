.weddingTitleSection {
    background: url('../img/bgWedding.jpg') no-repeat center center !important; }

.voucherTitleSection {
    background: url('../img/bgVoucher.jpg') no-repeat center center !important; }

.title-section-mainpage {
    background: url('../img/bg.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover; }

.title-section {
    position: relative;
    padding: 0px 0px 0px 0px;
    background-color: $grey2;

    &__videobg {}

    &__container {
        position: relative;
        z-index: 1;
        background: rgba(0, 0, 0, 0.6); } }

.title-elements {
    padding: 90px 0px 100px 0px;
    text-align: center;

    @media (max-width: 1600px) {
        padding: 60px 0px 80px 0px; }

    @media (max-width: 1500px) {
        padding: 50px 0px 70px 0px; }

    @media (max-width: 992px) {
        padding: 40px 0px 50px 0px; }

    .title1 {
        color: $gold;
        font-weight: $medium;
        font-size: 23px;
        text-transform: uppercase;

        h1 {
            color: $gold;
            font-weight: 800;
            font-size: 32px;
            text-transform: uppercase;

            @media (max-width: 992px) {
                font-size: 20px; } } }

    .titleimg {
        margin: 35px 0;

        @media (max-width: 1400px) {
            margin: 20px 0; }

        img {
            width: 383px;

            @media (max-width: 440px) {
                width: 100%; } } }

    .title2 {
        color: $white;
        font-weight: $ethin;
        font-size: 22px;
        text-transform: uppercase;
        margin: 0 0 50px 0;

        @media (max-width: 992px) {
            font-size: 16px;
            margin: 0 0 30px 0; } }

    .title3 {
        color: $gold;
        font-size: 18px;
        line-height: 18px;
        font-weight: 800;

        @media (max-width: 992px) {
            font-size: 14px;
            line-height: 14px; } }

    .title4 {
        color: $gold;
        font-size: 28px;
        line-height: 32px;
        text-transform: uppercase;
        font-weight: 300;

        @media (max-width: 992px) {
            font-size: 20px;
            line-height: 24px; } } }

.title-page {
    padding: 50px 0px 70px 0px;

    @media (max-width: 1200px) {
        padding: 30px 0px 35px 0px; }

    .title {
        color: $gold;
        font-size: 32px;
        line-height: 40px;
        font-weight: 800;

        @media (max-width: 1200px) {
            font-size: 22px;
            line-height: 30px; } } }

.mainbut {
    text-align: center;
    margin: 0 auto;
    margin-bottom: 80px; }

.scrolldown {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 0 40px 0;

    @media (max-width: 1600px) {
        padding: 0 0 30px 0; }

    &__text {
        color: $white;
        font-size: 12px;
        line-height: 12px;
        font-weight: 300;
        text-transform: uppercase;
        margin: 0 0 15px 0;
        opacity: 0.2; }

    &__el {
        display: flex;
        justify-content: center;
        width: 25px;
        height: 40px;
        border: 2px solid $white;
        border-radius: 10px;
        opacity: 0.4;

        &__dot {
            position: relative;
            top: 6px;
            width: 5px;
            height: 5px;
            border-radius: 5px;
            background: $white;

            animation: scroll 1.4s ease;
            animation-iteration-count: infinite;

            @keyframes scroll {
                0% {
                    transform: translateY(0);
                    opacity: 0; }

                20% {
                    opacity: 1; }

                70% {
                    opacity: 1; }

                100% {
                    transform: translateY(20px);
                    opacity: 0; } } } } }
