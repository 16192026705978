.services {

    &__sidebar {
        background: $grey3;
        border-radius: 5px;
        padding: 0;

        @media (max-width: 1200px) {
            display: none; }

        &__header {
            position: relative;
            display: flex;
            flex-direction: column;
            background: $green-2;
            padding: 18px 20px 20px 20px;
            margin: 0 0 10px 0;
            border-radius: 5px; }

        &__title {
            display: flex;
            align-items: center;
            font-size: 22px;
            line-height: 22px;
            font-weight: 800;
            color: $white;

            span {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                color: $white;
                background: $gold;
                font-size: 10px;
                line-height: 10px;
                font-weight: 800;
                height: 20px;
                width: 20px;
                border-radius: 50px;
                margin: 1px 0 0 8px; } }

        &__footer {
            display: flex;
            justify-content: center;
            text-align: center;
            background: $grey4;
            padding: 20px;
            margin: 10px 0 0 0;
            border-radius: 5px; }

        &__scrolldown {
            display: flex;
            align-items: center;
            text-align: center;
            color: $black;
            font-size: 12px;
            line-height: 12px;
            font-weight: 700;
            text-transform: uppercase;
            opacity: 0.4;

            span {
                font-size: 18px;
                line-height: 18px;
                margin: 0 5px 0 0; } }

        &__nav-inner {
            position: relative;
            z-index: 9;
            height: 100%;
            padding: 0 10px 0 20px;

            &::before {
                position: absolute;
                z-index: 10;
                top: 0;
                content: '';
                display: block;
                width: 87%;
                height: 20px;
                background: rgb(243,243,243);
                background: linear-gradient(180deg, rgba(246,246,246,1) 0%, rgba(246,246,246,0) 100%); }

            &::after {
                position: absolute;
                z-index: 10;
                bottom: 0;
                content: '';
                display: block;
                width: 87%;
                height: 20px;
                background: rgb(243,243,243);
                background: linear-gradient(0deg, rgba(246,246,246,1) 0%, rgba(246,246,246,0) 100%); } }

        &__nav {
            height: 500px;
            overflow-y: scroll;

            ul {
                padding: 0;
                list-style: none;
                padding: 10px 15px 0 0;

                li {
                    display: flex;
                    margin: 15px 0;

                    &:first-child {
                        margin: 0 0 15px 0; }

                    &:last-child {
                        margin: 15px 0 0 0; }

                    a {
                        display: flex;
                        position: relative;
                        font-size: 16px;
                        line-height: 24px;
                        color: $black;
                        text-decoration: underline;
                        font-weight: 500; } } } } }

    &__content {

        &__hero {
            display: flex;
            margin: 0 0 30px 0;

            @media (max-width: 992px) {
                flex-direction: column; }

            &__img {
                width: 40%;
                padding: 0 30px 0 0;

                @media (max-width: 1200px) {
                    width: 50%; }

                @media (max-width: 992px) {
                    width: 100%;
                    padding: 0;
                    order: 2;
                    margin: 20px 0 0; }

                img {
                    width: 100%;
                    border-radius: 5px;
                    margin: 0 !important; } }

            &__text {
                width: 60%;
                background: $grey3;
                border-radius: 5px;
                padding: 30px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                @media (max-width: 1200px) {
                    width: 50%; }

                @media (max-width: 992px) {
                    width: 100%;
                    order: 1;
                    padding: 20px; } } }

        h2 {
            font-size: 22px;
            line-height: 32px;
            font-weight: 800;
            margin: 0 0 8px 0;
            color: $gold; }

        p {
            font-size: 16px;
            line-height: 28px;
            font-weight: 400;
            margin: 8px 0;

            b {
                font-weight: 600; } }

        br {
            display: block;
            height: 14px;
            content: ""; }

        img {
            width: 100%;
            border-radius: 5px;
            margin: 30px 0;

            @media (max-width: 992px) {
                margin: 20px 0; } }

        &__callback {
            background: $grey3;
            border-radius: 5px;
            padding: 60px 80px;
            margin: 30px 0 0 0;

            @media (max-width: 992px) {
                padding: 30px; }

            &__title {
                text-align: center;
                font-size: 24px;
                line-height: 32px;
                font-weight: 800;
                color: $gold; }

            &__buttons {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 30px 0 0 0;

                @media (max-width: 992px) {
                    flex-direction: column; }

                &__button {}

                &__separator {
                    font-size: 14px;
                    line-height: 14px;
                    font-weight: 800;
                    text-transform: uppercase;
                    margin: 0 30px;
                    opacity: 0.2;

                    @media (max-width: 992px) {
                        margin: 15px 0; } } } } } }
