body {
    font-family: 'Exo 2', sans-serif !important;
    color: $black; }

.scroll-lock {
    overflow: hidden; }

main {
    padding: 0 30px !important;

    @media (max-width: 992px) {
        padding: 72px 15px 68px 15px !important; } }

a:active, a:focus {
    outline: none !important; }

textarea:focus, input:focus {
    outline: none; }

*:focus {
    outline: none; }

button:active {
    outline: none !important; }

button:focus {
    outline: none !important; }

button::-moz-focus-inner {
  border: 0 !important; }

textarea {
    resize:none {} }

textarea {
    resize:vertical {} }

textarea {
    resize:horizontal {} }


button:active, button:focus {
    outline: none; }

button::-moz-focus-inner {
    border: 0; }

a[href^="tel"] {
    color: inherit;
    text-decoration: none !important; }

h2 {
    font-size: 24px;
    line-height: 26px;
    font-weight: 300; }

h3 {
    font-size: 20px;
    line-height: 22px;
    font-weight: 300; }

h4 {
    font-size: 18px;
    line-height: 20px;
    font-weight: 400;
    text-transform: uppercase; }

p {
    font-size: 16px;
    line-height: 26px;
    font-weight: 300;
    margin: 20px 0; }

b {
    font-weight: 700; }

strong {
    font-weight: 500; }

a {
    color: $black;
    text-decoration: none;
    @include transit;

    &:hover {
        color: $black;
        text-decoration: none; } }

ul {
    padding: 0 0 0 20px;

    li {
        font-weight: 300; } }

ol {
    padding: 0 0 0 20px;

    li {
        font-weight: 300; } }

.clear {
	clear: both; }

.clear-m {
	clear: both; }

.clearfix {
    clear: both; }

.fw300 {
    font-weight: 300; }

.fw400 {
    font-weight: 400; }

.fw500 {
    font-weight: 500; }

.fw700 {
    font-weight: 700; }

.fs-normal {
    font-style: normal; }

.fs-italic {
    font-style: italic; }

.fs-10 {
    font-size: 10px; }

.fs-12 {
    font-size: 12px; }

.fs-14 {
    font-size: 14px; }

.fs-16 {
    font-size: 16px; }

.fs-18 {
    font-size: 18px; }

.fs-20 {
    font-size: 20px; }

.fs-22 {
    font-size: 22px; }

.fs-24 {
    font-size: 24px; }

.fs-26 {
    font-size: 26px; }
